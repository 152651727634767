import { ReactNode } from 'react';
import { Stack } from '@fluentui/react';
import { ActivityBar, ContentPane } from 'components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPatientIsMergedOrDeleted } from 'state/slices/patient/patient.selectors';

type Props = {
    children: ReactNode;
};

const SidebarLayout = (props: Props) => {
    const params = useParams<{ tenantId: string; patientId: string; encounterId: string }>();
    const { tenantId, patientId, encounterId } = params;

    const encounterPath = encounterId ? `/encounter/${encounterId}` : '';

    const patientIsMergedOrDeleted = useSelector(selectPatientIsMergedOrDeleted);
    const linksHidden = patientIsMergedOrDeleted === true;

    return (
        <Stack horizontal styles={{ root: { flex: 1, height: '100%', overflow: 'hidden' } }}>
            <ActivityBar
                activities={[
                    {
                        iconName: 'FullHistory',
                        keyString: 'audit',
                        path: `/${tenantId}/patient/${patientId}${encounterPath}/audit`,
                        tooltip: 'Audit Log',
                        exact: true,
                    },
                    {
                        iconName: 'UserOptional',
                        keyString: 'overview',
                        path: `/${tenantId}/patient/${patientId}${encounterPath}`,
                        tooltip: 'Overview',
                        exact: true,
                    },
                    {
                        iconName: 'Teeth',
                        keyString: 'charting',
                        path: `/${tenantId}/patient/${patientId}${encounterPath}/charting`,
                        tooltip: 'Charting',
                        exact: true,
                        hidden: linksHidden,
                    },
                    {
                        iconName: 'Perio',
                        keyString: 'perio',
                        path: `/${tenantId}/patient/${patientId}${encounterPath}/perio`,
                        tooltip: 'Perio',
                        exact: true,
                        hidden: linksHidden,
                    },
                    {
                        iconName: 'ClipboardList',
                        keyString: 'treatment',
                        path: `/${tenantId}/patient/${patientId}${encounterPath}/treatment-plans`,
                        tooltip: 'Treatment Plans',
                        exact: false,
                        hidden: linksHidden,
                    },
                    {
                        iconName: 'Money',
                        keyString: 'ledger',
                        path: `/${tenantId}/patient/${patientId}${encounterPath}/ledger`,
                        tooltip: 'Financial Ledger',
                        exact: false,
                        hidden: linksHidden,
                    },
                ]}
            />
            <ContentPane>{props.children}</ContentPane>
        </Stack>
    );
};

export default SidebarLayout;
