import { ICommandBarItemProps, ICommandBarProps, MessageBarType } from '@fluentui/react';
import useSelectedBatch from 'hooks/useSelectedBatch';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectedSelectedEncounterIdsWorkList,
    selectEncounterWorkListNoteModalSaving,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.selectors';
import { EncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import { selectCurrentWorkList, selectWorkListSaving } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListMessageBar } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { batchActions } from 'state/slices/tenant/batches.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { useApproveEncounters } from './useApproveEncounters';

export const worklistApprovalTypeMappings: Partial<Record<EncounterWorkList, 'reapprove' | 'approve'>> = {
    [EncounterWorkList.ReadyToRebill]: 'reapprove',
    [EncounterWorkList.ReadyToReview]: 'approve',
    [EncounterWorkList.ReadyToReviewOnHold]: 'approve',
};

export const NO_BATCH_ERROR_MESSAGE_BAR = {
    messageBarType: MessageBarType.blocked,
    message:
        'No batch has been selected. Please select a batch for the current day using the "Current Batch" button/field below.',
};

export const BATCH_NOT_FOR_TODAY_ERROR_MESSAGE_BAR = {
    messageBarType: MessageBarType.blocked,
    message:
        'The selected batch is not for today\'s date. Please select a batch for the current day using the "Current Batch" button/field below.',
};

const commandBarApproveLookup: Partial<
    Record<
        EncounterWorkList,
        {
            buttonLabel: string;
            loadingButtonLabel: string;
        }
    >
> = {
    [EncounterWorkList.ReadyToRebill]: {
        buttonLabel: 'Reapprove',
        loadingButtonLabel: 'Reapproving...',
    },
    [EncounterWorkList.ReadyToReview]: {
        buttonLabel: 'Approve',
        loadingButtonLabel: 'Approving...',
    },
    [EncounterWorkList.ReadyToReviewOnHold]: {
        buttonLabel: 'Approve',
        loadingButtonLabel: 'Approving...',
    },
};

/*
 * Command bar that is used to handle the approving/reapproving of encounters on the RTR/RTRe worklists
 * */
export default function useApproveWorklistCommandBar(): ICommandBarProps | undefined {
    const dispatch = useDispatch();
    const selectedBatch = useSelectedBatch();

    const encounterIds = useSelector(selectedSelectedEncounterIdsWorkList);
    const encounterWorkList = useSelector(selectCurrentWorkList) as EncounterWorkList | undefined;

    //the save encounter status thunk uses this area of state to keep track of when it is saving...
    const isSavingNote = useSelector(selectEncounterWorkListNoteModalSaving) === LoadingStatus.Pending;
    const isSavingWorklist = useSelector(selectWorkListSaving) === LoadingStatus.Pending;

    const disableCommandBarItems = isSavingNote || isSavingWorklist;

    const commandBarApproveButton = encounterWorkList ? commandBarApproveLookup[encounterWorkList] : undefined;
    const commandBarApprovalType = encounterWorkList ? worklistApprovalTypeMappings[encounterWorkList] : undefined;

    const [errors, approve] = useApproveEncounters(encounterIds, commandBarApprovalType);

    useEffect(() => {
        if (errors.length) {
            //2 different types of errors.
            //1: There is no batch selected for today.
            //2: There is a batch selected, but it is not for today's date.
            if (errors[0].fieldName === 'NoBatchSelected') dispatch(setWorkListMessageBar(NO_BATCH_ERROR_MESSAGE_BAR));
            if (errors[0].fieldName === 'BatchSameDay') dispatch(setWorkListMessageBar(BATCH_NOT_FOR_TODAY_ERROR_MESSAGE_BAR));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, dispatch]);

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'approveReapproveAction',
            text: isSavingWorklist ? commandBarApproveButton?.loadingButtonLabel : commandBarApproveButton?.buttonLabel,
            iconProps: { iconName: 'CheckMark' },
            disabled: disableCommandBarItems || !encounterIds.length,
            onClick: approve,
        },
        {
            key: 'batchForApproval',
            text: `Current Batch - ${selectedBatch ? `${classicDateOnly(selectedBatch.dateOfEntry)} (${selectedBatch.displayName})` : '(None)'}`,
            iconProps: { iconName: 'Bank' },
            disabled: disableCommandBarItems,
            onClick: () => {
                //Ensure the user can only select batches that are for today.
                dispatch(batchActions.setBatchFilters({ path: 'dateOfEntry', value: new Date().toISOString() }));
                dispatch(batchActions.setDisableBatchesFilters(true));
                dispatch(batchActions.setBatchPanelOpen(true));
            },
        },
    ];

    if (!encounterWorkList || !commandBarApproveButton) return undefined;

    return { items: commandBarItems };
}
