import {
    IButtonStyles,
    Stack,
    DirectionalHint,
    FontIcon,
    Link,
    Separator,
    TooltipHost,
    Text,
    IContextualMenuItem,
} from '@fluentui/react';
import { DocumentContext, DocumentRefTypes } from 'api/models/document.model';
import { IPatientInsurance } from 'api/models/patient.model';
import IUserTask from 'api/models/user-task.model';
import ContentCard from 'components/ContentCard';
import UserDisplayName from 'components/UserDisplayName';
import UserTasksBadge from 'components/UserTasks/UserTasksBadge';
import { isBefore, isAfter } from 'date-fns';
import { useSelector } from 'hooks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useDownloadFile } from 'hooks/useDocumentLink';
import { map } from 'lodash';
import { useMemo } from 'react';
import { selectPatientDocumentsByContext } from 'state/slices/documents/documents.selectors';
import {
    isActiveInsurance,
    isDeletedInsurance,
    isExpiredInsurance,
    selectEditPatientActiveInsurances,
} from 'state/slices/edit-patient/edit-patient.selectors';
import { setInsurancePrimary } from 'state/slices/edit-patient/edit-patient.slice';
import { benefitPlanCategories } from 'state/slices/tenant/benefit-plan.slice';
import { selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';
import { TaskType } from 'state/task-management/taskManagement.actions';
import {
    selectInsuranceFinancialInfoByTaskTypeLookup,
    selectRootFinancialInsuranceInfoByTaskTypeLookup,
} from 'state/task-management/taskManagement.slice';
import { classicDateOnly } from 'utils/dateOnly';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { IInsuranceComponentBaseProps } from './InsuranceCardViews';
import { insuranceIndexToPayerType } from './InsuranceProperties';

type InsuranceCardBaseProps = {
    insurance: IPatientInsurance;
} & IInsuranceComponentBaseProps;

function useInsuranceCardButtons({
    insurance,
    editInsurance,
    editEligibility,
    deleteInsurance,
    activateInsurance,
    expireInsurance,
}: InsuranceCardBaseProps) {
    const dispatch = useAppDispatch();

    const _tasks = useSelector(selectRootFinancialInsuranceInfoByTaskTypeLookup);
    const _activeInsurances = useSelector(selectEditPatientActiveInsurances);

    const _isActiveInsurance = useMemo(() => isActiveInsurance(insurance), [insurance]);
    const _isDeletedInsurance = useMemo(() => isDeletedInsurance(insurance), [insurance]);
    const _isExpiredInsurance = useMemo(() => isExpiredInsurance(insurance), [insurance]);

    const _indexOfActivePayer = useMemo(() => {
        if (!_isActiveInsurance) return -1;
        return _activeInsurances.findIndex((ins) => ins.id === insurance.id);
    }, [_isActiveInsurance, _activeInsurances, insurance]);

    const deleteButton = {
        key: 'Delete',
        text: !insurance.isDeleted ? 'Delete' : 'Activate',
        onClick: () => (!insurance.isDeleted ? deleteInsurance(insurance) : activateInsurance(insurance)),
    };

    const eligibilityButton = {
        key: 'eligibility',
        text: 'Edit Eligibility',
        onClick: () => editEligibility(insurance),
        iconProps: _tasks[TaskType.InsuranceEligibilityVerification] ? { iconName: 'Info', style: { color: 'red' } } : undefined,
    };

    const editButton = {
        key: 'editItem',
        text: 'Edit',
        onClick: () => editInsurance(insurance),
        iconProps: _tasks[TaskType.patientFinancialEffectiveDate] ? { iconName: 'Info', style: { color: 'red' } } : undefined,
    };

    const expireButton = {
        key: 'expire',
        text: 'Expire',
        onClick: () => expireInsurance(insurance),
    };

    let buttons: IContextualMenuItem[] = [];

    if (_isActiveInsurance) {
        buttons = [editButton, expireButton, deleteButton, eligibilityButton];
    } else if (_isExpiredInsurance) {
        buttons = [editButton, deleteButton];
    } else if (_isDeletedInsurance) {
        buttons = [deleteButton];
    }

    if (_indexOfActivePayer > 0)
        buttons.push({
            key: 'updatePrimaryInsurance',
            text: 'Set as Primary',
            onClick: () => {
                if (insurance) dispatch(setInsurancePrimary(insurance));
            },
        });

    return buttons;
}

function InsuranceCard(props: InsuranceCardBaseProps) {
    const { downloadDocumentById } = useDownloadFile();
    const { insurance } = props;

    const insuranceDocuments = useSelector((state) =>
        selectPatientDocumentsByContext(state, {
            context: DocumentContext.Insurance,
            reference: { type: DocumentRefTypes.PatientInsurance, id: insurance.id },
        }),
    );

    const insuranceCardButtons = useInsuranceCardButtons(props);

    const insuranceTasks = useSelector(selectInsuranceFinancialInfoByTaskTypeLookup);
    const benefitPlan = useSelector(benefitPlanCategories);
    const payersLookup = useSelector(selectTenantPayersData);
    const activePayers = useSelector(selectEditPatientActiveInsurances);

    const menuButtonStyles: IButtonStyles = {
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    const payer = insurance.insuranceId ? payersLookup[insurance.insuranceId] : undefined;

    const payerName = payer?.name ?? '';
    const payerIcon = payer?.specialty === 'Dental' ? 'Teeth' : '';
    const payerPhoneNumber = payer?.phone ? formatPhoneNumber(payer?.phone) : 'N/A';

    const planIDName = benefitPlan.find((plan) => plan.key === insurance.planId);

    const planName = planIDName?.text ?? '';
    const payerType = insuranceIndexToPayerType[activePayers.findIndex((ins) => ins.id === insurance?.id)];

    const expireInsurence = isBefore(new Date(insurance.expirationDate ?? ''), new Date()) ? ' - Expired' : '';

    const _insuranceTasks = (map(insuranceTasks[insurance.id]).filter((item) => item !== undefined) as IUserTask[]) ?? [];
    const policyHolderType = () => {
        if (insurance?.policyHolderType === 'Other Contact') {
            return ` ${insurance?.policyHolder?.firstName ?? ''} ${insurance?.policyHolder?.lastName ?? ''}  `;
        }
        return 'Self';
    };

    const getEffectiveDate = () => {
        const effectiveDate = insurance.effectiveDate;

        if (!effectiveDate) return 'N/A';
        if (!isAfter(new Date(insurance.effectiveDate), new Date('01/01/2001'))) return 'Invalid';

        return classicDateOnly(effectiveDate);
    };

    return (
        <ContentCard
            menuButtonProps={{
                menuProps: {
                    items: insuranceCardButtons,
                },
                styles: menuButtonStyles,
            }}
            rightContent={!insurance.isDeleted && <UserTasksBadge size={'small'} userTasks={_insuranceTasks}></UserTasksBadge>}
            title={''}
            isActive={!insurance.isDeleted}
        >
            <Stack>
                <Stack tokens={{ childrenGap: 10 }} verticalAlign="end" grow>
                    <Stack.Item>
                        <Stack>
                            <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <Text variant="mediumPlus" block>
                                    <FontIcon aria-label={payerIcon} iconName={payerIcon} />
                                </Text>
                                <Text variant="mediumPlus" block>
                                    {payerName} {expireInsurence}
                                </Text>
                            </Stack>
                            <Text variant="small">
                                Payer ID: <strong>{payer?.payerId ? payer.payerId : 'N/A'}</strong>
                            </Text>
                            <Text variant="small">
                                Plan: <strong>{insurance.planId ? planName : 'N/A'}</strong>
                            </Text>
                            {payerType && (
                                <Text variant="small">
                                    Type: <strong>{payerType}</strong>
                                </Text>
                            )}
                            <Text variant="small">
                                Policy Holder: <strong>{policyHolderType()}</strong>
                            </Text>
                            {insurance?.policyHolderType === 'Other Contact' && (
                                <Text variant="small">
                                    {"Patient's Relationship to Policy Holder"}:
                                    <strong>{insurance.policyHolder?.relationship ?? ''}</strong>
                                </Text>
                            )}
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Text variant="small">
                                    Member Id: <strong>{insurance.memberId ? insurance.memberId : 'N/A'}</strong>
                                </Text>
                                <Text variant="small">
                                    Group #: <strong>{insurance.groupNumber ? insurance.groupNumber : 'N/A'}</strong>
                                </Text>
                            </Stack>
                            <Text variant="small">
                                Insurance Phone: <strong>{payerPhoneNumber}</strong>
                            </Text>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Text variant="small">
                                    Eff. Date: <strong>{getEffectiveDate()}</strong>
                                </Text>
                                <Text variant="small">
                                    Exp. Date:{' '}
                                    <strong>
                                        {insurance.expirationDate ? classicDateOnly(insurance.expirationDate) : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack>
                            <TooltipHost
                                directionalHint={DirectionalHint.bottomCenter}
                                content={
                                    insurance.eligibility?.lastVerifiedBy ? (
                                        <Text>
                                            Last verified by: <UserDisplayName userId={insurance.eligibility?.lastVerifiedBy} />
                                        </Text>
                                    ) : undefined
                                }
                            >
                                <Text variant="small">
                                    Last Verified Date:{' '}
                                    <strong>
                                        {insurance?.eligibility?.lastVerified
                                            ? classicDateOnly(insurance?.eligibility.lastVerified)
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </TooltipHost>
                        </Stack>
                    </Stack.Item>
                </Stack>

                {insuranceDocuments.length && (
                    <>
                        <Separator />
                        <Stack>
                            <Text variant="small">
                                Documents:{' '}
                                {insuranceDocuments.map((doc, index) => (
                                    <Link
                                        key={index}
                                        onClick={() => {
                                            downloadDocumentById(doc.id);
                                        }}
                                        style={{ marginRight: 5 }}
                                    >
                                        <span>
                                            {doc.displayName}.{doc.extension}
                                        </span>
                                    </Link>
                                ))}
                            </Text>
                        </Stack>
                    </>
                )}
            </Stack>
        </ContentCard>
    );
}

export default InsuranceCard;
