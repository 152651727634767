import { IComboBoxOption } from '@fluentui/react';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { selectFilteredEncounterLedgerTotalsView } from '../ledger.selectors';

export const selectClaimHistoryState = (state: RootState) => state.ledger.claimHistory;

export const selectSelectedClaimHistoryEncounterSummary = createSelector(
    selectClaimHistoryState,
    (state) => state.selectedEncounterSummary,
);

export const selectClaimHistoryEncounterNoteModalOpen = createSelector(
    selectClaimHistoryState,
    (state) => state.encounterNoteModalOpen,
);


export const selectEncounterClaimsSummaryLoadingStatus = createSelector(
    selectClaimHistoryState,
    (state) => state.loadingEncounterClaimSummary,
);
export const selectEncounterClaimsSummaryError = createSelector(
    selectClaimHistoryState,
    (state) => state.encounterClaimSummaryError,
);
export const selectEncounterClaimsSummary = createSelector(selectClaimHistoryState, (state) => state.encounterClaimSummary);
export const selectEncounterClaimsSummaryCorrespondence = createSelector(
    selectEncounterClaimsSummary,
    (summary) => summary?.encounterCorrespondence ?? [],
);
export const selectEncounterClaimsSummaryReadyToBillHistory = createSelector(
    selectEncounterClaimsSummary,
    (summary) => summary?.readyToBillHistory ?? [],
);
export const selectEncounterClaimsSummaryClaimHistory = createSelector(
    selectEncounterClaimsSummary,
    (summary) => summary?.claimsHistory ?? [],
);

export const selectClaimsHistoryEncounterUniqueProvidersAsOptions = createSelector(selectFilteredEncounterLedgerTotalsView, (views) => {
        const options: IComboBoxOption[] = Array.from(new Set(views.map((views) => views.provider)))
            .map((provider) => ({
                key: provider ?? '',
                text: provider ?? '',
            }))
            .filter((option) => !!option.key);
        return options;
    },
);
