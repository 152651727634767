import { BatchField } from 'pages/components/Batch/BatchField';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { batchActions } from 'state/slices/tenant/batches.slice';

export default function ReapproveEncounterBatchField() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(batchActions.setBatchFilters({ path: 'dateOfEntry', value: new Date().toISOString() }));
        dispatch(batchActions.setDisableBatchesFilters(true));
    }, [dispatch]);

    return <BatchField textfieldProps={{ label: 'Current Batch' }} />;
}
