import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { IEncounterDetailsReportQuery, ReportingProviderType } from 'api/models/encounter-reports.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getValidationError } from 'hooks/useValidation';
import { orderBy } from 'lodash';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setEncounterDetailProp, toggleEncounterDetailsId } from 'state/slices/reporting/reporting.slice';
import { proceduresOptions } from 'state/slices/tenant/procedures.slice';
import {
    selectReportingProviderWithOptions,
    selectTreatingProviderOnlyOptions,
    selectTreatingProviderOptions,
} from 'state/slices/tenant/providers.slice';
import { selectTenantPayerOptions } from 'state/slices/tenant/tenant-payers.slice';
import { classicDateOnly } from 'utils/dateOnly';

export function useReportingProviderTypeProviderOptions(
    providerType: ReportingProviderType | undefined,
    selectedProviderIds: string[] | undefined,
) {
    const dispatch = useAppDispatch();

    //Provider options
    const _providerOptions = useSelector(selectReportingProviderWithOptions);
    const _treatingProviderOptions = useSelector(selectTreatingProviderOptions);
    const _treatingProviderOnlyOptions = useSelector(selectTreatingProviderOnlyOptions);

    const providerOptions = useMemo(() => {
        //Billing Provider type
        if (providerType === 'Billing') return _treatingProviderOptions;
        //Attending Provider or Treating Provider type:
        if (providerType === 'Attending' || providerType === 'Treating') return _treatingProviderOnlyOptions;
        //Otherwise return regular reporting provider options
        return _providerOptions;
    }, [providerType, _providerOptions, _treatingProviderOptions, _treatingProviderOnlyOptions]);

    useEffect(() => {
        //Ensure that the selected providers can remain selected after changing the provider type.
        if (selectedProviderIds?.length) {
            dispatch(
                setEncounterDetailProp({
                    path: 'provider',
                    value: selectedProviderIds.filter(
                        (providerId) => providerOptions.findIndex((option) => option.key === providerId) > -1,
                    ),
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerOptions, dispatch]);

    return providerOptions;
}

export default function EncountersDetails() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);

    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _payerOptions = useSelector(selectTenantPayerOptions);
    const _procedureOptions = useSelector(proceduresOptions);

    const query = useSelector(selectReportProperties) as IEncounterDetailsReportQuery;

    const providerOptions = useReportingProviderTypeProviderOptions(query?.providerType, query?.provider);

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
    }, [dispatch, tenantId]);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const dateTypeOptions: IDropdownOption[] = [
        { key: 'DOS', text: 'Service' },
        { key: 'DOE', text: 'Entry' },
    ];

    const providerTypeOptions: IDropdownOption[] = useMemo(
        () => [
            { key: '', text: '(Select)' },
            ...orderBy(
                [
                    { key: 'Billing', text: 'Billing Provider' },
                    { key: 'Attending', text: 'Attending Provider' },
                    { key: 'Treating', text: 'Treating Provider' },
                    { key: 'UDS', text: 'UDS Provider' },
                ],
                ['text'],
            ),
        ],
        [],
    );

    useEffect(() => {
        if (!query?.dateType) dispatch(setEncounterDetailProp({ path: 'dateType', value: 'DOS' }));
    }, []);

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.EncountersDetails]({ tenantId, ...query }));
    };

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Date Type',
                    validation: ['required'],
                    value: query.dateType,
                },
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query.endDate,
                },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item>
                    <Dropdown
                        label="Date Type"
                        options={dateTypeOptions}
                        required
                        selectedKey={query?.dateType}
                        placeholder="(Select)"
                        style={{ minWidth: 150 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setEncounterDetailProp({
                                        path: 'dateType',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        errorMessage={getValidationError(errors, 'Date Type') ? 'Date type is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setEncounterDetailProp({ path: 'startDate', value: newDate }));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setEncounterDetailProp({ path: 'endDate', value: newDate }));
                        }}
                        isReasonable
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>

                <Stack.Item>
                    <Dropdown
                        label="Provider Type "
                        options={providerTypeOptions}
                        selectedKey={query?.providerType}
                        placeholder="(Select)"
                        style={{ minWidth: 150 }}
                        onChange={(ev, option) => {
                            if (option)
                                dispatch(
                                    setEncounterDetailProp({
                                        path: 'providerType',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                    />
                </Stack.Item>
            </Stack>

            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleEncounterDetailsId({ id: option.key as string, path: 'locationOfCare' }));
                        }}
                        style={{ minWidth: 300 }}
                        selectedKey={query?.locationOfCare}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Providers)"
                        label="Providers"
                        multiSelect
                        options={providerOptions}
                        selectedKey={query?.provider}
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key) dispatch(toggleEncounterDetailsId({ id: option.key as string, path: 'provider' }));
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        options={_payerOptions}
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleEncounterDetailsId({ id: option.key as string, path: 'insuranceCarrier' }));
                        }}
                        selectedKey={query?.insuranceCarrier}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Procedures)"
                        label="Procedures"
                        options={_procedureOptions}
                        multiSelect
                        styles={{ callout: { maxWidth: 475 } }}
                        maxResults={100}
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key) dispatch(toggleEncounterDetailsId({ id: option.key as string, path: 'procedure' }));
                        }}
                        selectedKey={query?.procedure}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
