import { createSelector } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';
import { EncounterStatusFilterOptions, LedgerState, PostPaymentModalContext } from './ledger.state';
import { PaymentMethod } from 'api/models/patient.model';
import { IPaymentSource } from 'api/models/payment-source.model';
import { ICommonTransaction, IEncounterSummary } from 'api/models/encounter-ledger.model';
import { every, filter, groupBy, isEmpty, isEqual, map } from 'lodash';
import { IBillingProcedure } from 'api/models/billing-procedure.model';

import {
    selectActivePrimaryAppointmentPatientInsurance,
    selectSelectedAppointmentData,
} from '../scheduling/scheduling.selectors';

import { allPatientEncounters, primaryPatientInsurance, selectSelectedPatient } from '../patient/patient.selectors';
import { selectEditPatient } from '../edit-patient/edit-patient.selectors';
import { selectSignedTreatmentPlanView } from './treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import getBillingProcedureAmountFromProp from 'utils/getBillingProcedureAmountFromProp';
import { IComboBoxOption } from '@fluentui/react';
import { isAfter, isBefore } from 'date-fns';
import { classicDateOnly } from 'utils/dateOnly';

export const encounterLedgerState = (state: RootState): LedgerState => state.ledger;

export const selectShowLedgerFilters = createSelector(encounterLedgerState, (state) => state.showFilters);

export const selectEncounterLedgerFilters = createSelector(
    encounterLedgerState,
    ({ ledgerSummaryFilters }) => ledgerSummaryFilters,
);

export const selectLedgerActiveFiltersCount = createSelector(selectEncounterLedgerFilters, (filters) => {
    const count = filter(
        filters,
        (filter: string | string[] | boolean | undefined, key) =>
            (filters.relativeDateType ? key !== 'startDate' && key !== 'endDate' : true) &&
            (filters.encounterBillingStatus !== EncounterStatusFilterOptions.All) &&
            filter !== undefined &&
            (typeof filter === 'boolean' ? filter !== false : !isEmpty(filter)),
    ).length;

    return count;
});

export const selectEncounterLedgerTotalsViews = createSelector(encounterLedgerState, (state) => state.ledgerSummary ?? []);

const ledgerFilterFunctions = {
    insuranceBalanceGreaterThanZero: (item: IEncounterSummary) => item.insuranceBalance > 0,
    startDate: (item: IEncounterSummary, value: string) => {
        const dos = new Date(classicDateOnly(item.dateOfService));
        const valueDate = new Date(value);
        return isAfter(dos, valueDate) || isEqual(dos, valueDate);
    },
    endDate: (item: IEncounterSummary, value: string) => {
        const dos = new Date(classicDateOnly(item.dateOfService));
        const valueDate = new Date(value);
        return isBefore(dos, valueDate) || isEqual(dos, valueDate);
    },
    billingProviders: (item: IEncounterSummary, value: string[]) => {
        return value.indexOf(item.provider ?? '') > -1;
    },
};

export const selectFilteredEncounterLedgerTotalsView = createSelector(
    selectEncounterLedgerTotalsViews,
    selectEncounterLedgerFilters,
    (views, { encounterBillingStatus, startDate, endDate, insuranceBalanceGreaterThanZero, billingProviders, relativeDateType }) =>
        views.filter((summary) => {
            if (encounterBillingStatus === EncounterStatusFilterOptions.All) return true;
            if (encounterBillingStatus === EncounterStatusFilterOptions.Unapproved) return summary.encounterStatus !== 'Billed';
            if (encounterBillingStatus === EncounterStatusFilterOptions.Approved) return summary.encounterStatus === 'Billed';
        }).filter(view => (startDate ? ledgerFilterFunctions.startDate(view, startDate) : true) &&
            (endDate ? ledgerFilterFunctions.endDate(view, endDate) : true) &&
            (insuranceBalanceGreaterThanZero ? ledgerFilterFunctions.insuranceBalanceGreaterThanZero(view) : true) &&
            (billingProviders?.length ? ledgerFilterFunctions.billingProviders(view, billingProviders) : true)
        ) ,
);

export const selectLedgerViewEncounterUniqueProvidersAsOptions = createSelector(selectFilteredEncounterLedgerTotalsView, (views) => {
    const options: IComboBoxOption[] = Array.from(new Set(views.map((views) => views.provider)))
        .map((provider) => ({
            key: provider ?? '',
            text: provider ?? '',
        }))
        .filter((option) => !!option.key);
    return options;
},
);

//To ensure we don't have a loop in ledger view.tsx. This is passed as the items to the encounter ledger component.
export const selectEncounterLedgerTotalsViewDisplay = createSelector(
    encounterLedgerState,
    selectFilteredEncounterLedgerTotalsView,
    (state, views) => {
        return state.filteredLedgerSummaries?.length ? state.filteredLedgerSummaries ?? [] : views ?? [];
    },
);

export type LedgerLineItem = {
    totalCharges: number;
    insurancePayments: number;
    insuranceAdjustments: number;
    patientPayments: number;
    patientAdjustments: number;
    insuranceBalance: number;
    patientBalance: number;
    patientEstimate: number;
    patientReservePayments: number;
    totalBalance: number;
    totalCommonPatientFee?: number;
    totalEstimate?: number;
};

const ledgerLineItemPropertiesList: (keyof LedgerLineItem)[] = [
    'insuranceAdjustments',
    'totalCharges',
    'patientPayments',
    'patientAdjustments',
    'insuranceBalance',
    'insurancePayments',
    'patientBalance',
    'patientEstimate',
    'patientReservePayments',
    'totalBalance',
    'totalCommonPatientFee',
    'totalEstimate',
];

/**
 * Adds all totals for a ledger line item with LedgerLineItemProperties.
 *
 * @export
 * @param {LedgerLineItem[]} lineItems
 * @return {*}
 */
export function getLedgerLineItemTotals(lineItems: LedgerLineItem[]) {
    const totals: Record<keyof LedgerLineItem, number> = {
        insuranceAdjustments: 0,
        insuranceBalance: 0,
        insurancePayments: 0,
        patientAdjustments: 0,
        patientBalance: 0,
        patientEstimate: 0,
        patientPayments: 0,
        patientReservePayments: 0,
        totalBalance: 0,
        totalCharges: 0,
        totalCommonPatientFee: 0,
        totalEstimate: 0,
    };

    lineItems.forEach((item) => {
        ledgerLineItemPropertiesList.forEach((prop) => {
            totals[prop] += item[prop] ?? 0;
        });
    });
    return totals;
}

export const selectTotalPatientBalance = createSelector(selectEncounterLedgerTotalsViewDisplay, (summaries) => {
    return summaries.map((summary) => summary.patientBalance).reduce((summary1, summary2) => summary1 + summary2, 0);
});

//export const selectTotalPatientEstimate = createSelector(selectEncounterLedgerTotalsView, (summaries) => {
//return summaries
//.filter((summary) => summary.encounterStatus !== 'Billed')
//.map((summary) => summary.patientBalance)
//.reduce((bal1, bal2) => bal1 + bal2, 0);
//});

export const selectEncounterLedgerTransactionsLookup = createSelector(encounterLedgerState, (state) => state.procedureSummaries);

// Loading
export const selectEncounterLedgerViewLoadingStatus = createSelector(
    encounterLedgerState,
    ({ loadingLedgerSummary }) => loadingLedgerSummary,
);
export const selectEncounterLedgerViewLoading = createSelector(
    selectEncounterLedgerViewLoadingStatus,
    (loadingStatus) => loadingStatus === LoadingStatus.Pending,
);
export const selectEncounterLedgerTransactionsLoading = createSelector(
    encounterLedgerState,
    (state) => state.loadingProcedureSummaries === LoadingStatus.Pending,
);
export const selectCommonTransactionsLoading = createSelector(
    encounterLedgerState,
    (state) => state.loadingCommonTransactions === LoadingStatus.Pending,
);

//Payment Source
export const selectCurrentPaymentSource = createSelector(encounterLedgerState, (state) => state.currentPaymentSource);
export const selectPaymentModalOpen = createSelector(encounterLedgerState, ({ postPaymentModalOpen }) => postPaymentModalOpen);

//Payment Transactions
export const selectCurrentTransactions = createSelector(encounterLedgerState, (state) => state.currentTransactions);

//Negotiates between the selected patient or the selected appt patient based on if appointment data exists.
//Appointment date should not exist if the user is not on the scheduling screen.
export const selectIsPatientNonSlide = createSelector(
    primaryPatientInsurance,
    selectSelectedPatient,
    selectEditPatient,
    selectActivePrimaryAppointmentPatientInsurance,
    selectSelectedAppointmentData,
    (activeSelectedPatientIns, selectedPatient, editPatient, appointmentPatientIns, selectedAppointment) => {
        return (
            (selectedAppointment ? !!appointmentPatientIns : !!activeSelectedPatientIns) ||
            (selectedAppointment ? !editPatient?.slidingFees?.length : !selectedPatient?.slidingFees?.length)
        );
    },
);

export const selectIsPostPaymentModalInLedger = createSelector(
    encounterLedgerState,
    ({ postPaymentModalContext }) => postPaymentModalContext === PostPaymentModalContext.Ledger,
);
export const selectAdjustmentModalAmounts = createSelector(
    encounterLedgerState,
    ({ currentTransactions, totalAdjustmentAmount }) => {
        const adjustmentAmount = totalAdjustmentAmount ?? 0;

        const transactionTotal = currentTransactions.length
            ? currentTransactions.map((t) => t.amount).reduce((a, b) => a + b)
            : 0;

        const remainingAdjustmentAmount = Number((adjustmentAmount - transactionTotal).toFixed(2));

        return { adjustmentAmount, remainingAdjustmentAmount, transactionTotal };
    },
);

export const selectPaymentModalAmounts = (state: RootState, feeProp: keyof IBillingProcedure = 'commonPatientFee') => {
    const { currentBillingProcedures, currentTransactions, currentPaymentSource } = state.ledger;
    // Amounts
    const paymentAmount = Number((currentPaymentSource?.amount ?? 0).toFixed(2));

    const totalPaymentDue = currentBillingProcedures.length
        ? currentBillingProcedures
              .filter((bp) => getBillingProcedureAmountFromProp(bp, feeProp) > 0)
              .map((bp) => getBillingProcedureAmountFromProp(bp, feeProp))
              .reduce((a, b) => a + b, 0)
        : 0;

    const transactionTotal = currentTransactions.length ? currentTransactions.map((t) => t.amount).reduce((a, b) => a + b) : 0;

    const remainingPaymentAmount = Number((paymentAmount - transactionTotal).toFixed(2));

    // Conditionals
    const paymentNotEmpty = paymentAmount > 0;
    const transactionMoreThanPayment = transactionTotal > paymentAmount;

    const paymentAndTransactionsValid = Number(transactionTotal.toFixed(2)) === paymentAmount;
    const canSavePaymentAndTransactions = paymentAmount !== 0 && paymentAndTransactionsValid && !!paymentAmount;

    return {
        paymentAmount,
        totalPaymentDue,
        transactionTotal,
        remainingPaymentAmount,

        paymentNotEmpty,
        transactionMoreThanPayment,
        paymentAndTransactionsValid,
        canSavePaymentAndTransactions,
    };
};

export const selectHidePaymentSourceIdentifier = createSelector(selectCurrentPaymentSource, (paymentSource) => {
    return paymentSource?.method === undefined || paymentSource?.method === PaymentMethod.Cash;
});

export const selectIsCheckOrAuth = createSelector(selectCurrentPaymentSource, (paymentSource) => {
    return paymentSource?.method === PaymentMethod.Check ? 'Check' : 'Auth';
});

//Billing Procedures
export const selectCurrentBillingProcedures = createSelector(encounterLedgerState, (state) => state.currentBillingProcedures);

export interface ILedgerTransactionView {
    billingProcedures: IBillingProcedure[];
    encounterId: string;
    encounterNumber?: string;
    encounterDate?: string;
    locationOfCareName?: string;
    totalPatientBalance: number;
    totalPatientAdjustments: number;
    totalPatientPayments: number;
}

export const selectLedgerCurrentBillingProceduresView = createSelector(
    selectCurrentBillingProcedures,
    allPatientEncounters,
    (billingProcedures, encounters) => {
        const proceduresByEncounter = groupBy(billingProcedures, (proc) => proc.encounterId);

        const views: ILedgerTransactionView[] = map(proceduresByEncounter, (procs, encounterId) => {
            const encounter = encounters.find((e) => e.id === encounterId);

            const totalPatientBalance = procs.map((p) => p.commonPatientFee).reduce((f1, f2) => f1 + f2);
            const totalPatientAdjustments = procs.map((p) => p.patientAdjustments).reduce((a1, a2) => a1 + a2, 0);
            const totalPatientPayments = procs.map((p) => p.patientPayments).reduce((p1, p2) => p1 + p2);

            return {
                billingProcedures: procs,
                encounterId,
                encounterNumber: encounter?.encounterNumber,
                encounterDate: encounter?.encounterDate,
                locationOfCareName: procs[0].locationOfCareName,
                totalPatientBalance,
                totalPatientAdjustments,
                totalPatientPayments,
            };
        });
        return views;
    },
);

export const selectUnscheduledProceduresCreditAmount = createSelector(selectSignedTreatmentPlanView, (view) => {
    return (view?.phases ?? []).map((p) => p.prePayments ?? 0).reduce((a, b) => a + b, 0);
});

export const selectBillingProcedureAmountDue = createSelector(selectCurrentBillingProcedures, (billingProcedures) => {
    return billingProcedures.length ? billingProcedures.map((bp) => bp.patientEstimate).reduce((a, b) => a + b) : 0;
});

//Totals Modal
export const selectTotalsModalIsOpen = createSelector(encounterLedgerState, (state) => state.totalsModalIsOpen);

export const selectCommonTransactions = createSelector(encounterLedgerState, (state) => state.commonTransactions ?? []);
export const selectPaymentSources = createSelector(encounterLedgerState, (state) => state.paymentSources ?? []);
export const selectTotalsViewData = createSelector(
    selectCurrentBillingProcedures,
    selectCommonTransactions,
    selectPaymentSources,
    (billingProcedures, transactions, paymentSources): TotalsViewModel[] => {
        return paymentSources.map((paymentSource) => {
            return {
                ...paymentSource,
                transactions: filter(transactions, ['paymentSourceId', paymentSource.id]).map((transaction) => ({
                    transaction,
                    billingProcedure: billingProcedures.find((bp) => bp.id === transaction.chartProcedureId),
                })),
            };
        });
    },
);

export type TotalsViewModel = IPaymentSource & {
    transactions: TotalsTransactions[];
};
export type TotalsTransactions = { transaction: ICommonTransaction; billingProcedure?: IBillingProcedure };

// Adjustment Modal
export const adjustmentModalIsOpen = createSelector(encounterLedgerState, ({ adjustmentModalOpen }) => adjustmentModalOpen);
export const saveAdjustmentsDisabled = createSelector(
    selectAdjustmentModalAmounts,
    selectCurrentTransactions,
    ({ remainingAdjustmentAmount }, transactions) => {
        const noAmountsEntered = every(transactions, (t) => !t.amount);
        const isRemainingAdjustmentAmount = remainingAdjustmentAmount > 0;
        return noAmountsEntered || isRemainingAdjustmentAmount;
    },
);

// Prepayment Modal
export const prepaymentModalOpen = createSelector(encounterLedgerState, ({ prePaymentModalOpen }) => prePaymentModalOpen);

export const selectCurrentEncounterRebillLoading = createSelector(
    encounterLedgerState,
    (_: RootState, encounterId?: string) => encounterId,
    (state, encounterId) => (encounterId ? state.encounterRebillLoading.includes(encounterId) : false),
);
