import { CommandBar, ScrollablePane, SearchBox, Separator, Stack, Toggle } from '@fluentui/react';
import { IEncounterSummary } from 'api/models/encounter-ledger.model';
import { useSelector } from 'hooks';
import { isNaN } from 'lodash';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { selectFilteredEncounterLedgerTotalsView, selectEncounterLedgerTotalsViewDisplay, selectShowLedgerFilters, selectLedgerActiveFiltersCount } from 'state/slices/ledger/ledger.selectors';
import { setEncounterLedgerSummaryFilter, setFilteredEncounterSummaries, toggleLedgerFilters } from 'state/slices/ledger/ledger.slice';
import { EncounterStatusFilterOptions } from 'state/slices/ledger/ledger.state';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';
import { useHasClaimsHistoryAccess } from '../Ledger';
import LedgerViewFilters from '../LedgerViewFilters';
import EncounterLedger from './EncounterLedger';


export default function LedgerView() {
    const dispatch = useDispatch();

    const { url } = useRouteMatch();
    const { push } = useHistory();

    const _selectEncounterLedgerTotalsView = useSelector(selectFilteredEncounterLedgerTotalsView);
    const _selectEncounterLedgerTotalsViewDisplay = useSelector(selectEncounterLedgerTotalsViewDisplay);

    const showFilters = useSelector(selectShowLedgerFilters);
    const filtersCount = useSelector(selectLedgerActiveFiltersCount);

    const hasClaimsHistoryAccess = useHasClaimsHistoryAccess();


    const [search, setSearch] = useState<string>();

    const results = useMemo(() => {
        const lowercaseSearch = search?.toLowerCase();

        function filterByProp(encounter: IEncounterSummary, prop: keyof IEncounterSummary) {
            if (lowercaseSearch && encounter[prop])
                return (encounter[prop] as string | number).toString().toLowerCase().startsWith(lowercaseSearch);
            return false;
        }

        function filterProvider(encounter: IEncounterSummary) {
            if (lowercaseSearch && isNaN(+lowercaseSearch) && encounter.provider) {
                return encounter.provider.toLowerCase().indexOf(lowercaseSearch) > -1;
            }
            return false;
        }

        function filterLocationOfCare(encounter: IEncounterSummary) {
            if (lowercaseSearch && isNaN(+lowercaseSearch) && encounter.locationOfCare) {
                return filterByProp(encounter, 'locationOfCare');
            }
            return false;
        }

        function filterEncounterNumber(encounter: IEncounterSummary) {
            if (lowercaseSearch && !isNaN(+lowercaseSearch) && encounter.encounterNumber) {
                return filterByProp(encounter, 'encounterNumber');
            }
            return false;
        }

        function filterDateOfService(encounter: IEncounterSummary) {
            if (lowercaseSearch && encounter.dateOfService) {
                return classicDateOnly(encounter.dateOfService).startsWith(lowercaseSearch);
            }
            return false;
        }

        function filterPatientBalance(encounter: IEncounterSummary) {
            if (lowercaseSearch?.length && lowercaseSearch.charAt(0) === '$' && encounter.patientBalance !== undefined)
                return usdCurrencyFormatter.format(encounter.patientBalance).startsWith(lowercaseSearch);
            return false;
        }

        if (lowercaseSearch) {
            return _selectEncounterLedgerTotalsView.filter((encounter) => {
                return (
                    filterProvider(encounter) ||
                    filterDateOfService(encounter) ||
                    filterEncounterNumber(encounter) ||
                    filterLocationOfCare(encounter) ||
                    filterPatientBalance(encounter)
                );
            });
        }

        return [];
    }, [search, _selectEncounterLedgerTotalsView]);

    const _onSearchChange = (ev?: React.ChangeEvent, value?: string) => {
        setSearch(value ?? '');
    };

    const _onEncounterClick = (ev?: React.MouseEvent, item?: IEncounterSummary) => {
        if (item?.encounterId) {
            push(`${url}/claim-history/${item.encounterId}`);
        }
    };

    const _toggleLedgerViewFilters = () => {
        dispatch(toggleLedgerFilters());
    }

    useEffect(() => {
        return () => {
            dispatch(
                setEncounterLedgerSummaryFilter(EncounterStatusFilterOptions.All),
            )
            dispatch(setFilteredEncounterSummaries(undefined))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Ensure that we set the filtered encounter summaries. This is so the overall patient balance display takes into account the fuse search.
    useEffect(() => {
        if (search && results.length) {
            dispatch(setFilteredEncounterSummaries(results))
        } else {
            dispatch(setFilteredEncounterSummaries(undefined))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])


    return (
        <Stack grow>
            <CommandBar
                styles={{ root: { paddingLeft: 0 } }}

                items={[
                    {
                        key: 'filters',
                        onRender: () => (
                            <Stack horizontal tokens={{ childrenGap: 10 }} style={{ display: 'flex', alignItems: 'center' }}>
                                <SearchBox
                                    styles={{ root: { width: 375 } }}
                                    value={search}
                                    onChange={_onSearchChange}
                                    placeholder={'Search by Enc. #, LOC, Provider, Patient Balance, or DOS'}
                                />
                            </Stack>
                        ),
                    },
                    {
                        key: 'divider',
                        onRender: () => <Separator styles={{ root: { paddingLeft: 20, paddingRight: 20 } }} vertical />,
                    },
                    {
                        key: 'showFilters',
                        onRender: () => {
                            return (
                                <Toggle
                                    checked={showFilters}
                                    onClick={_toggleLedgerViewFilters}
                                    label={`Show All Filters${filtersCount > 0 ? ` (${filtersCount} active)` : ''}`}
                                    inlineLabel
                                    styles={{ root: { marginBottom: 0 } }}
                                />
                            );
                        },
                    },
                ]}
            />
            <LedgerViewFilters />
            <Stack styles={{ root: { padding: 5 } }} horizontal>
            </Stack>
            <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
                <ScrollablePane>
                    <EncounterLedger
                        views={_selectEncounterLedgerTotalsViewDisplay}
                        onEncounterClick={hasClaimsHistoryAccess ? _onEncounterClick : undefined}
                    />
                </ScrollablePane>
            </div>
        </Stack>
    );
}
