import { IconButton, SelectionMode, Stack, useTheme } from '@fluentui/react';
import { ExpandableDetailsList, Field } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { every } from 'lodash';
import { getTransactionAmount } from 'pages/Ledger/LedgerUtils';
import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { distributePaymentAndUpdateTransactions } from 'state/slices/ledger/ledger.actions';
import {
    ILedgerTransactionView,
    selectAdjustmentModalAmounts,
    selectCurrentPaymentSource,
    selectCurrentTransactions,
    selectLedgerCurrentBillingProceduresView,
    selectPaymentModalAmounts,
} from 'state/slices/ledger/ledger.selectors';
import { usdCurrencyFormatter } from 'utils';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { FinancialModalType, ProcedureTransactionTable, TransactionAmountField } from './PostPaymentModal';

type Props = {
    ledgerTransactionView: ILedgerTransactionView | undefined;
    type: FinancialModalType;
};

export function LedgerViewPostTransactionAmountField({ ledgerTransactionView, type }: Props) {
    const dispatch = useDispatch();

    const paymentSource = useSelector(selectCurrentPaymentSource);

    const currentTransactions = useSelector(selectCurrentTransactions);

    const { transactionTotal: paymentTransactionTotal, remainingPaymentAmount } = useSelector(selectPaymentModalAmounts);
    const {
        transactionTotal: adjustmentTransactionTotal,
        remainingAdjustmentAmount,
        adjustmentAmount,
    } = useSelector(selectAdjustmentModalAmounts);

    const amounts = {
        [FinancialModalType.Payment]: {
            transactionTotal: paymentTransactionTotal,
            remainingAmount: remainingPaymentAmount,
            totalAmount: paymentSource?.amount ?? 0,
        },
        [FinancialModalType.Adjustment]: {
            transactionTotal: adjustmentTransactionTotal,
            remainingAmount: remainingAdjustmentAmount,
            totalAmount: adjustmentAmount,
        },
    };

    const { remainingAmount, totalAmount, transactionTotal } = amounts[type];

    const billingProceduresFromView = ledgerTransactionView?.billingProcedures ?? [];

    const encounterTransactions = currentTransactions.filter((t) => {
        const chartProcedure = billingProceduresFromView.find((p) => p.id === t.chartProcedureId);
        const commonPatientFee = chartProcedure?.commonPatientFee ?? 0;

        return t.encounterId === ledgerTransactionView?.encounterId && t.chartProcedureId && commonPatientFee > 0;
    });

    const transactionsFromView = encounterTransactions.filter(
        (t) => billingProceduresFromView.findIndex((bp) => bp.id === t.chartProcedureId) > -1,
    );

    const transactionAmount = encounterTransactions.length
        ? encounterTransactions.map((t) => t.amount)?.reduce((t1, t2) => t1 + t2)
        : 0;

    const transactionsAmountFromView = encounterTransactions.length
        ? transactionsFromView.map((t) => t.amount)?.reduce((t1, t2) => t1 + t2)
        : 0;

    const ledgerViewBalance = ledgerTransactionView?.totalPatientBalance ?? 0;

    const isOverpay = every(billingProceduresFromView, (proc) => proc.commonPatientFee <= 0);

    const disabled = !totalAmount || isOverpay;

    const _onChangeAmount = (e?: FormEvent, input?: number) => {
        if (ledgerTransactionView?.encounterId && ledgerTransactionView.billingProcedures.length) {
            const amount = getTransactionAmount({
                input,
                patientFee: ledgerViewBalance,
                remainingPaymentAmount: remainingAmount,
                transactionAmount,
            });
            dispatch(
                distributePaymentAndUpdateTransactions(amount, ledgerTransactionView.encounterId, billingProceduresFromView),
            );
        }
    };

    return (
        <Stack title={usdCurrencyFormatter.format(transactionAmount)} horizontal>
            <Field.Currency
                prefix="$"
                value={transactionsAmountFromView}
                onChange={_onChangeAmount}
                disabled={disabled}
                max={ledgerViewBalance < 0 ? undefined : ledgerViewBalance}
            />
            <IconButton
                iconProps={{ iconName: 'Add' }}
                title="Add max amount"
                disabled={
                    !totalAmount ||
                    !remainingAmount ||
                    transactionTotal >= totalAmount ||
                    transactionsAmountFromView >= ledgerViewBalance ||
                    isOverpay
                }
                onClick={() => _onChangeAmount(undefined, ledgerViewBalance)}
            />
        </Stack>
    );
}

type PostPaymentLedgerDetailsListProps = {
    canOverpay: boolean;
};

export default function PostPaymentLedgerDetailsList({ canOverpay }: PostPaymentLedgerDetailsListProps) {
    const views = useSelector(selectLedgerCurrentBillingProceduresView);
    const { palette } = useTheme();

    const columns: ISortableColumn<ILedgerTransactionView>[] = [
        {
            key: 'date',
            fieldName: 'encounterDate',
            minWidth: 60,
            maxWidth: 60,
            name: 'Date',
            onRender: (item) => <span>{convertDashedDateString(item?.encounterDate)}</span>,
        },
        {
            key: 'encounterNumber',
            fieldName: 'encounterNumber',
            minWidth: 60,
            maxWidth: 60,
            name: 'Enc. #',
        },
        {
            key: 'locationOfCareName',
            fieldName: 'locationOfCareName',
            minWidth: 60,
            maxWidth: 120,
            name: 'LOC',
        },
        {
            key: 'totalPatientPayments',
            fieldName: 'totalPatientPayments',
            minWidth: 110,
            maxWidth: 130,
            name: 'Patient Payments',
            onRender: (item) => <span>{usdCurrencyFormatter.format(item?.totalPatientPayments ?? 0)}</span>,
        },
        {
            key: 'totalPatientBalance',
            fieldName: 'totalPatientBalance',
            minWidth: 100,
            maxWidth: 130,
            name: 'Patient Balance',
            onRender: (item) => <span>{usdCurrencyFormatter.format(item?.totalPatientBalance ?? 0)}</span>,
        },
        {
            name: 'Payment',
            key: 'payment',
            onRender: (item) => (
                <LedgerViewPostTransactionAmountField type={FinancialModalType.Payment} ledgerTransactionView={item} />
            ),
            minWidth: 165,
            maxWidth: 165,
        },
    ];

    return (
        <ExpandableDetailsList<ILedgerTransactionView>
            items={views}
            sortOnMount
            allowMultipleRowsExpanded
            canExpandAll
            showGrid
            sortColumns={['date']}
            initialSortDirection={['desc']}
            columns={columns}
            onRenderExpandedRowContent={(props) => {
                return (
                    <Stack style={{ paddingLeft: 2, backgroundColor: palette.neutralQuaternaryAlt }}>
                        <ProcedureTransactionTable
                            canOverpay={canOverpay}
                            overrideColumns={[
                                { name: 'Code', key: 'code', fieldName: 'procedureCode', minWidth: 50, maxWidth: 50 },
                                {
                                    name: 'Desc.',
                                    key: 'desc',
                                    fieldName: 'procedureDescription',
                                    minWidth: 100,
                                    maxWidth: 110,
                                    onRender: (item) => (
                                        <span title={item?.procedureDescription}>{item?.procedureDescription}</span>
                                    ),
                                },
                                {
                                    name: 'Pt. Payments',
                                    key: 'appliedPayments',
                                    onRender: (item) => (
                                        <span>
                                            Applied: {usdCurrencyFormatter.format(item?.patientPayments ?? 0)}
                                            <br /> Reserve: {usdCurrencyFormatter.format(item?.patientReservePayments ?? 0)}
                                        </span>
                                    ),
                                    minWidth: 130,
                                    maxWidth: 140,
                                },
                                {
                                    name: 'Remaining Balance',
                                    key: 'commonPatientFee',
                                    onRender: (item) => <span>{usdCurrencyFormatter.format(item?.commonPatientFee ?? 0)}</span>,
                                    minWidth: 120,
                                },
                                {
                                    name: 'Payment',
                                    key: 'payment',
                                    onRender: (item) => (
                                        <TransactionAmountField
                                            feeProp="commonPatientFee"
                                            type={FinancialModalType.Payment}
                                            canOverpay={canOverpay}
                                            billingProcedure={item}
                                        />
                                    ),
                                    minWidth: 165,
                                    maxWidth: 165,
                                },
                            ]}
                            billingProcedures={props?.item.billingProcedures}
                        />
                    </Stack>
                );
            }}
            selectionMode={SelectionMode.none}
        />
    );
}
