import { DetailsRow, IColumn, IDetailsFooterProps, SelectionMode, Text } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ToothArea } from 'api/models/tooth-area';
import { SortableDetailsList } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { getInsuranceTypes } from 'pages/components/PatientDetailsBanner';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import {
    selectActivePrimaryAppointmentPatientInsurance,
    selectCompletedProceduresAsList,
} from 'state/slices/scheduling/scheduling.selectors';
import { selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';
import { getShorthandToothAreas, usdCurrencyFormatter } from 'utils';
import { ColumnTotalsLookup, getColumnTotalsForItems } from 'utils/getColumnTotalsForItems';

//Code, Tooth #, Area/Stage, description, Fee
export const baseCheckoutProceduresDetailsListColumns: ISortableColumn<IBillingProcedure>[] = [
    {
        key: 'code',
        minWidth: 60,
        maxWidth: 150,
        isResizable: true,
        name: 'Code',
        isRowHeader: true,
        fieldName: 'procedureCode',
    },
    {
        key: 'tooth #',
        minWidth: 80,
        maxWidth: 150,
        isResizable: true,
        name: 'Tooth #',
        fieldName: 'toothIds',
        onRender: (item) => {
            const toothIds = item?.toothIds?.join(', ') || 'N/A';
            return (
                <Text title={toothIds} variant="smallPlus">
                    {toothIds}
                </Text>
            );
        },
    },
    {
        key: 'area/stage',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        name: 'Area/Stage',
        fieldName: 'areas',
        onRender: (item) => {
            if (!item) return null;
            const areas = item.areas?.length ? getShorthandToothAreas(item.areas as (keyof typeof ToothArea)[]) : '';
            const text = `${areas}${item.stage ? `${areas ? ' / ' : ''}${item.stage}` : ''}` || 'N/A';
            return (
                <Text title={text} variant="smallPlus">
                    {text}
                </Text>
            );
        },
    },
    {
        key: 'procedureDescription',
        minWidth: 100,
        maxWidth: 180,
        isResizable: true,
        name: 'Description',
        fieldName: 'procedureDescription',
        onRender: (item) => {
            if (!item) return null;
            const text = item.procedureDescription || 'N/A';
            return (
                <Text title={text} variant="smallPlus">
                    {text}
                </Text>
            );
        },
    },
    {
        key: 'ucrFee',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        name: 'Fee',
        fieldName: 'ucrFee',
        onRender: (item) => renderCurrencyColumn(item, 'ucrFee'),
    },
];

function renderCurrencyColumn(item: IBillingProcedure | undefined, prop: keyof IBillingProcedure | undefined) {
    if (!item || !prop || typeof item[prop] !== 'number') return null;
    const value = usdCurrencyFormatter.format((item[prop] as number) ?? 0);
    return (
        <Text title={value} variant="smallPlus">
            {value}
        </Text>
    );
}

type CompletedProceduresTotalsLookup = ColumnTotalsLookup<IBillingProcedure & { actualPatientEstimate: number }>;

export function useCheckoutCompletedProceduresColumns() {
    const editPatient = useSelector(selectEditPatient);
    const activeAppointmentPatientInsurance = useSelector(selectActivePrimaryAppointmentPatientInsurance);
    const payersWithDisplayName = useSelector(selectTenantPayersData);
    const { slidingFee } = getInsuranceTypes(editPatient, payersWithDisplayName);

    function getInsuredPatientEstimate(item?: IBillingProcedure | undefined) {
        return (item ? item.patientEstimate + item.patientReservePayments * -1 : 0).toString();
    }

    function getPatientAmount(item?: IBillingProcedure | undefined) {
        return (item ? item.ucrFee + item.slidingFeeAdjustments : 0).toString();
    }

    const getColumns = useCallback(() => {
        //Is insurance
        if (activeAppointmentPatientInsurance) {
            //Do stuff
            const cols: ISortableColumn<IBillingProcedure>[] = [
                ...baseCheckoutProceduresDetailsListColumns,
                {
                    key: 'contractedFee',
                    name: 'Allowed Est.',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'contractedFee',
                    onRender: (item) => renderCurrencyColumn(item, 'contractedFee'),
                },
                {
                    key: 'insuranceEstimate',
                    name: 'Insurance Est.',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'insuranceEstimate',
                    onRender: (item) => renderCurrencyColumn(item, 'insuranceEstimate'),
                },
                {
                    key: 'actualPatientEstimate',
                    name: 'Pt. Est.',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'actualPatientEstimate',
                    getValueKey: getInsuredPatientEstimate,
                    onRender: (item) => {
                        const value = getInsuredPatientEstimate(item);
                        return (
                            <Text title={value} variant="smallPlus">
                                {usdCurrencyFormatter.format(+value)}
                            </Text>
                        );
                    },
                },
                {
                    key: 'patientReservePayments',
                    name: 'Pt. Payments',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'patientReservePayments',
                    onRender: (item) => renderCurrencyColumn(item, 'patientReservePayments'),
                },
                {
                    key: 'patientEstimate',
                    name: 'Remaining Pt. Est.',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'patientEstimate',
                    onRender: (item) => renderCurrencyColumn(item, 'patientEstimate'),
                },
            ];
            return cols;
        }
        //Is slide
        if (slidingFee) {
            //Do stuff
            const cols: ISortableColumn<IBillingProcedure>[] = [
                ...baseCheckoutProceduresDetailsListColumns,
                {
                    key: 'slidingFeeAdjustments',
                    name: 'Pt. Discounts',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'slidingFeeAdjustments',
                    onRender: (item) => renderCurrencyColumn(item, 'slidingFeeAdjustments'),
                },
                {
                    key: 'patientAmount',
                    name: 'Patient Amount',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'patientAmount',
                    getValueKey: getPatientAmount,
                    onRender: (item) => {
                        const patientAmount = getPatientAmount(item);
                        const formattedValue = usdCurrencyFormatter.format(+patientAmount);
                        return (
                            <Text title={formattedValue} variant="smallPlus">
                                {formattedValue}
                            </Text>
                        );
                    },
                },
                {
                    key: 'additionalOrLabFee',
                    name: 'Lab/Materials Fee',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'additionalOrLabFee',
                    onRender: (item) => renderCurrencyColumn(item, 'additionalOrLabFee'),
                },
                {
                    key: 'patientReservePayments',
                    name: 'Pt. Payments',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'patientReservePayments',
                    onRender: (item) => renderCurrencyColumn(item, 'patientReservePayments'),
                },
                {
                    key: 'patientEstimate',
                    name: 'Pt. Due',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    fieldName: 'patientEstimate',
                    onRender: (item) => renderCurrencyColumn(item, 'patientEstimate'),
                },
            ];

            return cols;
        }
        //Otherwise they are self pay
        const cols: ISortableColumn<IBillingProcedure>[] = [
            ...baseCheckoutProceduresDetailsListColumns,
            {
                key: 'patientReservePayments',
                name: 'Pt. Payments',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                fieldName: 'patientReservePayments',
                onRender: (item) => renderCurrencyColumn(item, 'patientReservePayments'),
            },
            {
                key: 'patientEstimate',
                name: 'Pt. Due',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                fieldName: 'patientEstimate',
                onRender: (item) => renderCurrencyColumn(item, 'patientEstimate'),
            },
        ];
        return cols;
    }, [activeAppointmentPatientInsurance, slidingFee]);

    return getColumns();
}

export default function CheckoutCompletedProceduresDetailsList() {
    const completedProcedures = useSelector(selectCompletedProceduresAsList);
    const columns = useCheckoutCompletedProceduresColumns();

    const totalsLookup = useMemo(
        () => getColumnTotalsForItems<IBillingProcedure>(completedProcedures, columns),
        [completedProcedures, columns],
    );

    return (
        <SortableDetailsList<IBillingProcedure>
            columns={columns}
            selectionMode={SelectionMode.none}
            items={completedProcedures}
            onRenderDetailsFooter={(props) => _onRenderDetailsFooter(props, columns.length + 1, totalsLookup)}
        />
    );
}

function _onRenderDetailsFooter(
    detailsFooterProps: IDetailsFooterProps | undefined,
    itemIndex: number,
    totalsLookup: CompletedProceduresTotalsLookup,
) {
    if (!detailsFooterProps) return null;

    return (
        <DetailsRow
            {...detailsFooterProps}
            itemIndex={itemIndex}
            columns={detailsFooterProps.columns.map((col) => ({ ...col, onRender: undefined }))}
            item={{}}
            groupNestingDepth={detailsFooterProps.groupNestingDepth}
            onRenderItemColumn={(item, index, column) => _renderDetailsFooterItemColumn(item, index, column, totalsLookup)}
        />
    );
}

const _renderDetailsFooterItemColumn = (
    item: IBillingProcedure,
    index: number | undefined,
    column: IColumn | undefined,
    totalsLookup: CompletedProceduresTotalsLookup,
) => {
    if (column?.key === 'procedureDescription') return <b>Totals:</b>;
    if (!column || baseCheckoutProceduresDetailsListColumns.slice(0, -1).findIndex((col) => column.key === col.key) > -1)
        return null;
    const value = totalsLookup[column.fieldName as keyof IBillingProcedure] ?? 0;
    const formattedValue = usdCurrencyFormatter.format(value);
    return <b title={formattedValue}>{formattedValue}</b>;
};
