import { useValidation } from 'hooks';
import useSelectedBatch from 'hooks/useSelectedBatch';
import { IValidationConfig, IValidationError } from 'hooks/useValidation';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { markEncountersAsRebilled } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import { markEncountersAsBilled } from 'state/slices/encounter/encounter.actions';
import dateOnly from 'utils/dateOnly';

const approveEncounterActionLookup = {
    reapprove: markEncountersAsRebilled,
    approve: markEncountersAsBilled,
};

export function useApproveEncounters(
    encounterIds: (string | undefined)[],
    approvalType: 'reapprove' | 'approve' | undefined,
    onApproved?: () => void,
): [IValidationError[], () => void] {
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const selectedBatch = useSelectedBatch();

    const onApproveAction = () => {
        if (approvalType) {
            const idsToApprove = encounterIds.filter((id) => id !== undefined) as string[];
            dispatch(
                approveEncounterActionLookup[approvalType]({
                    tenantId,
                    encounterIds: idsToApprove,
                    batchId: selectedBatch?.id,
                }),
            );
            if (onApproved) {
                onApproved();
            }
        }
    };

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'NoBatchSelected',
            validation: ['required'],
            value: selectedBatch,
        },
        {
            fieldName: 'BatchSameDay',
            validation: ['required'],
            value: selectedBatch?.dateOfEntry
                    ? dateOnly(selectedBatch.dateOfEntry) === dateOnly(new Date().toISOString())
                    ? true
                    : undefined
                : undefined,
        },
    ];

    const [errors, approve, cleanupValidationErrors] = useValidation(validationConfig, onApproveAction);

    useEffect(() => {
        return () => {
            cleanupValidationErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [errors, approve];
}
