import { ICommonTransaction, ILedgerTransactionSummaryView, IEncounterSummary } from 'api/models/encounter-ledger.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { Dictionary } from '@reduxjs/toolkit';
import { IPaymentSource } from 'api/models/payment-source.model';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { LedgerPaymentHistoryState, paymentHistoryInitialState } from './payment-history/payment-history.state';
import initialPatientPaymentsAndAdjustmentsState, {
    PatientPaymentsAndAdjustmentsState,
} from './patient-payments-and-adjustments/patient-payments-and-adjustments.state';
import {
    initialTreatmentPlanCreditAndUACState,
    TreatmentPlanCreditAndUACState,
} from './treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.state';
import { adjustmentHistoryInitialState, LedgerAdjustmentHistoryState } from './adjustment-history/adjustment-history.state';
import initialClaimHistoryState, { LedgerClaimHistoryState } from './claim-history/claim-history.state';
import uacDistributionState, { UACDistributionState } from './uac-distribution/uac-distribution.state';
import { RelativeDateType } from 'components/Field/RelativeDateRangeField';

export interface LedgerState {
    // Totals Modals
    totalsModalIsOpen: boolean;
    paymentSources?: IPaymentSource[];
    commonTransactions?: ICommonTransaction[];

    // Loading Statuses
    loadingLedgerSummary: LoadingStatus;
    loadingCommonTransactions: LoadingStatus;
    loadingProcedureSummaries: LoadingStatus;

    // For adding payment and transactions
    currentPaymentSource?: IPaymentSource;
    currentTransactions: ICommonTransaction[];
    currentBillingProcedures: IBillingProcedure[];

    // Patient ledger page data
    ledgerSummary?: IEncounterSummary[];
    filteredLedgerSummaries?: IEncounterSummary[];

    showFilters?: boolean;
    ledgerSummaryFilters: LedgerSummaryFilters;

    procedureSummaries: Dictionary<ILedgerTransactionSummaryView>;

    paymentHistory: LedgerPaymentHistoryState;
    adjustmentHistory: LedgerAdjustmentHistoryState;
    claimHistory: LedgerClaimHistoryState;

    postPaymentModalContext?: PostPaymentModalContext;
    postPaymentModalOpen: boolean;

    prePaymentModalOpen: boolean;

    adjustmentModalOpen: boolean;
    totalAdjustmentAmount?: number;

    patientPaymentsAndAdjustments: PatientPaymentsAndAdjustmentsState;
    patientTreatmentPlanCreditAndUAC: TreatmentPlanCreditAndUACState;
    uacDistribution: UACDistributionState;

    encounterRebillLoading: string[];
}

export type LedgerSummaryFilters = {
    encounterBillingStatus?: EncounterStatusFilterOptions;
    insuranceBalanceGreaterThanZero?: boolean;
    startDate?: string;
    endDate?: string;
    relativeDateType?: RelativeDateType;
    billingProviders?: string[];
};

export enum PostPaymentModalContext {
    Ledger = 'ledger',
    Checkout = 'checkout',
}

export enum PostPaymentModalView {
    Overpay = 'overpay',
    Payment = 'payment',
}

export enum EncounterStatusFilterOptions {
    All = 'All',
    Approved = 'Approved',
    Unapproved = 'Unapproved',
}

export const initialState: LedgerState = {
    loadingLedgerSummary: LoadingStatus.Idle,
    loadingCommonTransactions: LoadingStatus.Idle,
    loadingProcedureSummaries: LoadingStatus.Idle,

    ledgerSummaryFilters: {
        encounterBillingStatus: EncounterStatusFilterOptions.All,
    },

    currentTransactions: [],
    procedureSummaries: {},
    currentBillingProcedures: [],

    totalsModalIsOpen: false,
    showFilters: true,
    paymentHistory: paymentHistoryInitialState,
    adjustmentHistory: adjustmentHistoryInitialState,
    claimHistory: initialClaimHistoryState,

    postPaymentModalOpen: false,
    prePaymentModalOpen: false,
    adjustmentModalOpen: false,

    patientPaymentsAndAdjustments: initialPatientPaymentsAndAdjustmentsState,
    patientTreatmentPlanCreditAndUAC: initialTreatmentPlanCreditAndUACState,
    uacDistribution: uacDistributionState,

    encounterRebillLoading: [],
};
