import {
    ChoiceGroup,
    Stack,
    TextField,
    SelectionMode,
    IChoiceGroupOption,
    IconButton,
    Text,
    useTheme,
    Separator,
    Toggle,
    TooltipHost,
    DirectionalHint,
    MessageBar,
    CommandBar,
} from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { PaymentMethod } from 'api/models/patient.model';
import { Field, SortableDetailsList, TModal } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'hooks';
import useValidation, { getValidationError, IValidationConfig, IValidationError } from 'hooks/useValidation';
import { Cookies } from 'interfaces/cookies';
import { RouteParams } from 'interfaces/route-params';
import { BatchField } from 'pages/components/Batch/BatchField';
import { getTransactionAmount } from 'pages/Ledger/LedgerUtils';
import { FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { onBatchIdChanged, savePaymentAndTransactions } from 'state/slices/ledger/ledger.actions';
import {
    prepaymentModalOpen,
    selectAdjustmentModalAmounts,
    selectBillingProcedureAmountDue,
    selectCurrentBillingProcedures,
    selectCurrentPaymentSource,
    selectCurrentTransactions,
    selectHidePaymentSourceIdentifier,
    selectIsCheckOrAuth,
    selectIsPatientNonSlide,
    selectIsPostPaymentModalInLedger,
    selectPaymentModalAmounts,
    selectPaymentModalOpen,
} from 'state/slices/ledger/ledger.selectors';
import {
    adjustAllTransactions,
    cleanupCurrentTransactionsAndSource,
    clearTransactions,
    payAllTransactions,
    updateCurrentPaymentSourceProps,
    updateTransactionAmount,
} from 'state/slices/ledger/ledger.slice';
import { getShorthandToothAreas, usdCurrencyFormatter } from 'utils';
import PostPaymentLedgerDetailsList from './PostPaymentLedgerDetailsList';
import { CheckoutPaymentModalDetailsList } from './CheckoutPaymentModelDetailsList';

/**
 * Understanding overpay.
 *
 * Two views one overpay and one for normal payments.
 *
 * To overpay on a procedure you must switch views on the modal.
 *
 * To enter a payment that is greater than the total est balance you must go to the overpay tab.
 *
 * Enter a payment for today that is greater than payment est
 *
 * For overpay, maybe we remove the pay all button, and pay max buttons from rows.
 * Clear button will stay.
 *
 * modifying today's payment after overpaying will still allow you to enter
 */

type PostPaymentModalProps = {
    patientId?: string;
};

export function PostPaymentModal({ patientId }: PostPaymentModalProps): JSX.Element | null {
    const dispatch = useDispatch();
    const [cookies] = useCookies();

    const { tenantId } = useParams<RouteParams>();

    const [canOverpay, setCanOverpay] = useState(false);

    const isOpen = useSelector(selectPaymentModalOpen);
    const prepaymentIsOpen = useSelector(prepaymentModalOpen);
    const billingProcedures = useSelector(selectCurrentBillingProcedures);

    const isLedger = useSelector(selectIsPostPaymentModalInLedger);
    const showPostPaymentLedgerDetailsList = isLedger && !prepaymentIsOpen;

    const paymentSource = useSelector(selectCurrentPaymentSource);
    const amountDue = useSelector(selectBillingProcedureAmountDue);

    const hideMethodIdentifier = useSelector(selectHidePaymentSourceIdentifier);
    const isCheckOrAuth = useSelector(selectIsCheckOrAuth);

    const { canSavePaymentAndTransactions, remainingPaymentAmount, paymentAmount, transactionTotal } = useSelector((state) =>
        selectPaymentModalAmounts(state, showPostPaymentLedgerDetailsList ? 'commonPatientFee' : 'patientEstimate'),
    );
    const _onDismiss = () => {
        dispatch(cleanupCurrentTransactionsAndSource());
        setCanOverpay(false);
    };

    const _handleClearTransactions = () => dispatch(clearTransactions());

    const _onSave = () => {
        if (patientId) {
            dispatch(savePaymentAndTransactions({ tenantId, patientId }));
        }
    };

    const toggleCanOverpay = () => {
        const newValue = !canOverpay;
        setCanOverpay(newValue);
        if (!newValue) {
            _handleClearTransactions();
        }
    };

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Payment Method',
            validation: ['required'],
            value: paymentSource?.method,
        },
        {
            fieldName: "Today's Payment",
            validation: ['required'],
            value: paymentSource?.amount,
        },
        {
            fieldName: 'Batch',
            validation: ['required'],
            value: paymentSource?.dateOfEntry,
        },
        {
            fieldName: 'Notes',
            validation: ['characterLimit'],
            value: paymentSource?.note,
            itemOptions: { characterLimit: 150 },
        },
    ];

    if (!hideMethodIdentifier)
        validationConfig.push({
            fieldName: isCheckOrAuth,
            validation: ['required'],
            value: paymentSource?.methodIdentifier,
        });

    const [errors, submit, handleCleanupErrors] = useValidation(validationConfig, _onSave);

    useEffect(() => {
        return () => {
            handleCleanupErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isOpen) handleCleanupErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const batchId = cookies[Cookies.SelectedBatch];

    useEffect(() => {
        // Handle updating batchId when it changes; 🝪
        dispatch(onBatchIdChanged(batchId));
    }, [batchId, dispatch]);

    const paymentAndTransactionsValid = Number(transactionTotal.toFixed(2)) === paymentAmount;

    const canSaveOverpay = paymentAmount !== 0 && paymentAndTransactionsValid && !!paymentAmount;
    const canSave = canOverpay ? !canSaveOverpay : !canSavePaymentAndTransactions;

    return (
        <TModal
            title={prepaymentIsOpen ? 'Enter Treatment Plan Credit' : 'Enter Payment'}
            isDraggable
            mainButtons={[
                { primary: true, text: 'Save', disabled: canSave, onClick: submit },
                { text: 'Cancel', onClick: _onDismiss },
            ]}
            modalProps={{ isOpen, isBlocking: true, onDismiss: _onDismiss }}
            rightFooterContent={
                <Stack horizontalAlign="end">
                    <Text>
                        Total patient est. balance: <strong>{usdCurrencyFormatter.format(amountDue)}</strong>
                    </Text>
                    <Text>
                        Payment amount: <strong>{usdCurrencyFormatter.format(paymentSource?.amount ?? 0)}</strong>
                    </Text>
                    <Text>
                        Unallocated amount: <strong>{usdCurrencyFormatter.format(remainingPaymentAmount)}</strong>
                    </Text>
                </Stack>
            }
        >
            <Stack style={{ padding: 10 }} grow tokens={{ childrenGap: 10 }}>
                <TooltipHost
                    directionalHint={DirectionalHint.leftCenter}
                    content="Allow overpayments on procedures with zero or less balance. Allows today's payment to exceed total patient est. balance."
                >
                    <Toggle
                        checked={canOverpay}
                        onChange={toggleCanOverpay}
                        styles={{ root: { margin: 0, maxWidth: 190 } }}
                        inlineLabel
                        label="Allow Overpayment"
                    />
                </TooltipHost>
                <PaymentSource
                    showPostPaymentLedgerDetailsList={showPostPaymentLedgerDetailsList}
                    canOverpay={canOverpay}
                    errors={errors}
                />
                <Separator>Transactions</Separator>
                <DistributePaymentButtons
                    type={FinancialModalType.Payment}
                    canOverpay={canOverpay}
                    showPostPaymentLedgerDetailsList={showPostPaymentLedgerDetailsList}
                />
                <Stack>
                    {!billingProcedures?.length ? (
                        <MessageBar>No procedures have been found.</MessageBar>
                    ) : showPostPaymentLedgerDetailsList ? (
                        <PostPaymentLedgerDetailsList canOverpay={canOverpay} />
                    ) : (
                        <CheckoutPaymentModalDetailsList canOverpay={canOverpay} billingProcedures={billingProcedures} />
                    )}
                </Stack>
            </Stack>
        </TModal>
    );
}

type PaymentSourceProps = {
    errors: IValidationError[];
    canOverpay: boolean;
    showPostPaymentLedgerDetailsList: boolean;
};

export enum FinancialModalType {
    Adjustment = 'Adjustment',
    Payment = 'Payment',
}

type DistributePaymentButtonsProps = {
    type: FinancialModalType;
    canOverpay: boolean;
    showPostPaymentLedgerDetailsList?: boolean;
};

export function DistributePaymentButtons({
    type,
    canOverpay,
    showPostPaymentLedgerDetailsList,
}: DistributePaymentButtonsProps): JSX.Element {
    const dispatch = useDispatch();

    const _handlePayAll = () => {
        switch (type) {
            case FinancialModalType.Payment:
                dispatch(
                    payAllTransactions({ feeProp: showPostPaymentLedgerDetailsList ? 'commonPatientFee' : 'patientEstimate' }),
                );
                break;
            case FinancialModalType.Adjustment:
                dispatch(adjustAllTransactions());
                break;
        }
    };
    const _handleClearTransactions = () => dispatch(clearTransactions());

    return (
        <CommandBar
            styles={{ root: { padding: 0 } }}
            items={[
                {
                    key: 'payAll',
                    text: `Distribute ${type}`,
                    iconProps: { iconName: 'Add' },
                    onClick: () => {
                        _handlePayAll();
                    },
                    disabled: canOverpay,
                },
                {
                    key: 'clear',
                    text: 'Clear All',
                    iconProps: { iconName: 'Clear' },
                    onClick: () => {
                        _handleClearTransactions();
                    },
                },
            ]}
        />
    );
}

function PaymentSource({ errors, canOverpay, showPostPaymentLedgerDetailsList }: PaymentSourceProps) {
    const dispatch = useDispatch();
    const { palette } = useTheme();
    const paymentSource = useSelector(selectCurrentPaymentSource);

    const methodIdentifierLabel = useSelector(selectIsCheckOrAuth);
    const hideMethodIdentifier = useSelector(selectHidePaymentSourceIdentifier);

    const { totalPaymentDue } = useSelector((state) =>
        selectPaymentModalAmounts(state, showPostPaymentLedgerDetailsList ? 'commonPatientFee' : 'patientEstimate'),
    );
    const paymentMethodStyles = {
        root: {
            display: 'inline-flex',
            paddingLeft: 10,
        },
    };

    const _onChangeAmount = (e: FormEvent, value?: number) =>
        dispatch(updateCurrentPaymentSourceProps({ path: 'amount', value }));

    const _onChangeMethod = (e?: FormEvent, option?: IChoiceGroupOption) => {
        if (paymentSource?.methodIdentifier !== option?.key) {
            dispatch(updateCurrentPaymentSourceProps({ path: 'methodIdentifier', value: '' }));
        }
        dispatch(updateCurrentPaymentSourceProps({ path: 'method', value: option?.key }));
    };

    const _onChangeMethodIdentifier = (e?: FormEvent, value?: string) =>
        dispatch(updateCurrentPaymentSourceProps({ path: 'methodIdentifier', value }));

    const _onChangeNote = (e?: FormEvent, value?: string) => dispatch(updateCurrentPaymentSourceProps({ path: 'note', value }));

    return (
        <Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <BatchField textfieldProps={{ label: 'Batch' }} error={errors} />
                <Field.Date label="Entry date" value={paymentSource?.dateOfEntry} autoComplete="off" readOnly />
                <Field.Currency
                    label="Today's payment"
                    prefix="$"
                    value={paymentSource?.amount ?? 0}
                    onChange={_onChangeAmount}
                    required
                    errorMessage={getValidationError(errors, "Today's Payment") ? "Today's Payment is required." : undefined}
                    max={canOverpay ? undefined : totalPaymentDue}
                />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack>
                    <ChoiceGroup
                        label="Payment Method"
                        required
                        selectedKey={paymentSource?.method}
                        onChange={_onChangeMethod}
                        options={[
                            {
                                key: PaymentMethod.Visa,
                                text: PaymentMethod.Visa,
                                styles: {
                                    root: {
                                        display: 'inline-flex',
                                    },
                                },
                            },
                            {
                                key: PaymentMethod.Mastercard,
                                text: PaymentMethod.Mastercard,
                                styles: paymentMethodStyles,
                            },
                            {
                                key: PaymentMethod.AmEx,
                                text: PaymentMethod.AmEx,
                                styles: paymentMethodStyles,
                            },
                            {
                                key: PaymentMethod.Discover,
                                text: PaymentMethod.Discover,
                                styles: paymentMethodStyles,
                            },
                            {
                                key: PaymentMethod.Cash,
                                text: PaymentMethod.Cash,
                                styles: paymentMethodStyles,
                            },
                            {
                                key: PaymentMethod.Check,
                                text: 'Check/ACH',
                                styles: paymentMethodStyles,
                            },
                            {
                                key: PaymentMethod.Credit,
                                text: PaymentMethod.Credit,
                                styles: paymentMethodStyles,
                            },
                        ]}
                    />
                    {getValidationError(errors, 'Payment Method') ? (
                        <Text variant="small" style={{ color: palette.redDark }}>
                            Payment Method is required.
                        </Text>
                    ) : null}
                </Stack>
                {!hideMethodIdentifier && (
                    <Stack grow>
                        <TextField
                            required
                            label={methodIdentifierLabel}
                            prefix="#"
                            value={paymentSource?.methodIdentifier}
                            onChange={_onChangeMethodIdentifier}
                            errorMessage={
                                getValidationError(errors, methodIdentifierLabel)
                                    ? `${methodIdentifierLabel} is required.`
                                    : undefined
                            }
                        />
                    </Stack>
                )}
            </Stack>
            <TextField
                multiline
                rows={1}
                label="Notes"
                value={paymentSource?.note}
                onChange={_onChangeNote}
                description={`Used ${paymentSource?.note?.length ?? 0} of 150 characters.`}
                errorMessage={getValidationError(errors, 'Notes') ? 'Character limit must be below 150.' : ''}
            />
        </Stack>
    );
}

type ProcedureTransactionTableProps = {
    billingProcedures: IBillingProcedure[];
    overrideColumns?: ISortableColumn<IBillingProcedure>[];
    canOverpay: boolean;
};

export function ProcedureTransactionTable({ billingProcedures, overrideColumns, canOverpay }: ProcedureTransactionTableProps) {
    const nonSlide = useSelector(selectIsPatientNonSlide);

    const columns: ISortableColumn<IBillingProcedure>[] = [
        {
            name: 'Tooth',
            key: 'tooth',
            onRender: (item) => <span>{item?.toothIds?.join(', ')}</span>,
            minWidth: 50,
            maxWidth: 50,
        },
        { name: 'Code', key: 'code', fieldName: 'procedureCode', minWidth: 50, maxWidth: 50 },
        {
            name: 'Desc.',
            key: 'desc',
            fieldName: 'procedureDescription',
            minWidth: 100,
            maxWidth: 110,
            onRender: (item) => <span title={item?.procedureDescription}>{item?.procedureDescription}</span>,
        },
        {
            key: 'stage',
            minWidth: 50,
            name: 'Stage',
            fieldName: 'procedureStage',
            onRender: (item) => (
                <Text title={item?.stage ?? ''} variant="smallPlus">
                    {item?.stage ?? ''}
                </Text>
            ),
        },
        {
            name: 'Area',
            key: 'area',
            onRender: (item) => <span>{getShorthandToothAreas(item?.areas)}</span>,
            minWidth: 80,
            maxWidth: 90,
        },
        {
            name: 'Pat. Est.',
            key: 'patientEst',
            onRender: (item) => <span>{usdCurrencyFormatter.format(item?.patientEstimate ?? 0)}</span>,
            minWidth: 80,
            maxWidth: 100,
        },
        {
            name: 'Payment',
            key: 'payment',
            onRender: (item) => (
                <TransactionAmountField
                    feeProp="commonPatientFee"
                    type={FinancialModalType.Payment}
                    billingProcedure={item}
                    canOverpay={canOverpay}
                />
            ),
            minWidth: 145,
            maxWidth: 145,
        },
    ];

    if (nonSlide) {
        columns.splice(5, 0, {
            name: 'Ins. Est.',
            key: 'insuranceEstimate',
            onRender: (item) => <span>{usdCurrencyFormatter.format(item?.insuranceEstimate ?? 0)}</span>,
            minWidth: 100,
            maxWidth: 100,
        });
    } else {
        columns.splice(5, 0, {
            key: 'additionalOrLabFee',
            minWidth: 100,
            name: 'Lab/Material Fee.',
            fieldName: 'additionalOrLabFee',
            onRender: (item) => {
                return (
                    <Stack grow>
                        <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.additionalOrLabFee ?? 0)}</Text>
                    </Stack>
                );
            },
        });
    }

    return (
        <SortableDetailsList<IBillingProcedure>
            selectionMode={SelectionMode.none}
            items={billingProcedures}
            showGrid
            columns={overrideColumns ?? columns}
        />
    );
}

type TransactionAmountFieldProps = {
    billingProcedure?: IBillingProcedure;
    type: FinancialModalType;
    canOverpay: boolean;
    feeProp: keyof IBillingProcedure;
};

export function TransactionAmountField({
    billingProcedure,
    canOverpay,
    type,
    feeProp,
}: TransactionAmountFieldProps): JSX.Element | null {
    const dispatch = useDispatch();

    const paymentSource = useSelector(selectCurrentPaymentSource);
    const { remainingPaymentAmount } = useSelector((state) => selectPaymentModalAmounts(state, feeProp));

    const { remainingAdjustmentAmount, adjustmentAmount } = useSelector(selectAdjustmentModalAmounts);

    const { remainingAmount, totalAmount } = {
        [FinancialModalType.Payment]: {
            remainingAmount: remainingPaymentAmount,
            totalAmount: paymentSource?.amount ?? 0,
        },
        [FinancialModalType.Adjustment]: {
            remainingAmount: remainingAdjustmentAmount,
            totalAmount: adjustmentAmount,
        },
    }[type];

    const transactions = useSelector(selectCurrentTransactions);

    const fee: number = billingProcedure
        ? typeof billingProcedure[feeProp] === 'number'
            ? (billingProcedure[feeProp] as number)
            : 0
        : 0;

    const transaction = transactions.find((transaction) => {
        const transactionHasEncounterId = !!transaction.encounterId;
        const doesTransactionEncounterMatchProcedure = transactionHasEncounterId
            ? (transaction?.encounterId ?? '') === (billingProcedure?.encounterId ?? '')
            : true;

        return transaction.chartProcedureId === billingProcedure?.id && doesTransactionEncounterMatchProcedure;
    });

    if (!transaction) return null;

    const disabled = !totalAmount && !transaction.amount;

    const _onChangeAmount = (e?: FormEvent, input?: number) => {
        const inputValue = input ?? 0;
        dispatch(
            updateTransactionAmount({
                id: transaction?.id,
                amount: canOverpay
                    ? inputValue
                    : getTransactionAmount({
                        input,
                        patientFee: fee,
                        remainingPaymentAmount: remainingAmount,
                        transactionAmount: transaction.amount,
                    }),
            }),
        );
    };

    const _onSetMaxAmount = () => {
        dispatch(
            updateTransactionAmount({
                id: transaction.id,
                amount: getTransactionAmount({
                    input: fee,
                    patientFee: fee,
                    remainingPaymentAmount: remainingAmount,
                    transactionAmount: transaction.amount,
                }),
            }),
        );
    };

    return (
        <Stack title={usdCurrencyFormatter.format(transaction.amount)} horizontal>
            <Field.Currency
                prefix="$"
                value={transaction.amount}
                onChange={_onChangeAmount}
                disabled={disabled}
                max={canOverpay ? undefined : fee}
            />
            {fee > 0 && (
                <IconButton
                    iconProps={{ iconName: 'Add' }}
                    title="Add max amount"
                    disabled={canOverpay || fee <= transaction.amount || !paymentSource?.amount || remainingPaymentAmount <= 0}
                    onClick={_onSetMaxAmount}
                />
            )}
        </Stack>
    );
}
