import { Dictionary } from '@reduxjs/toolkit';
import IAdjustmentReason from 'api/models/adjustment-reason.model';
import { IReport, IEmbedReportModel } from 'api/models/embed-report.model';
import ILocationOfCare from 'api/models/Scheduling/locationsOfCare.model';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import {
    clinicalNoteReportsInitialState,
    IClinicalNoteReportsState,
} from './clinical-notes-reports/clinical-notes-reports.state';
import reportingGroupsInitialState, { ReportingGroupsState } from './reporting-groups/reporting-groups.state';
import LookupBase, { LookupCodeBase } from 'api/models/lookup-base.model';

export interface DailyScheduleReportProperties {
    apptStart?: string;
    apptEnd?: string;
    locationOfCareIds?: string[];
    treatingProviderIds?: string[];
    hygienistIds?: string[];
    groupCol1?: string;
    groupCol2?: string;
}

export interface IPatientRouteSlipReportProperties {
    appointmentId: string;
}

export interface IEncounterReportProperties {
    encounterId: string;
}
export interface IChartNoteReportProperties {
    chartNoteId: string[];
}

export interface ITreatmentPlanReportProperties {
    chartTreatmentPlanId: string[];
}
export interface IPredeterminationFormProperties {
    chartTreatmentPlanId: string;
    chartTreatmentPlanProcedureId: string[];
    treatingProviderId: string;
    locationOfCareId: string;
}
export interface IProviderProductivityReportProperties {
    startDate?: string;
    endDate?: string;
    treatingProvider?: string[];
}

export interface IBatchReportProperties {
    startDate?: string;
    endDate?: string;
    batchStatus?: number;
}

export interface ISpecificBatchReportProperties {
    batchId: string;
}

export interface IPatientStatementReportProperties {
    patientId?: string;
}

export interface IToothLedgerReportProperties {
    startDate?: string;
    endDate?: string;
}

export interface IReportingState {
    reports?: Dictionary<IReport>;
    loading: LoadingStatuses;

    loadingSelectedReport: LoadingStatuses;
    selectedReport?: IEmbedReportModel;

    reportProperties: unknown;
    reportValidationErrors: IValidationError[];

    extraReportData?: {
        locationsOfCareLoading?: LoadingStatus;
        locationsOfCare?: Dictionary<ILocationOfCare>;

        adjustmentReasonsLoading?: LoadingStatus;
        adjustmentReasons?: Dictionary<IAdjustmentReason>;
    };

    clinicalNoteReports: IClinicalNoteReportsState;

    reportingGroups: ReportingGroupsState;
    encounterStatus: Dictionary<LookupCodeBase>;
    loadingEncounterStatus: LoadingStatus;
    patientTracker: Dictionary<LookupCodeBase>;
    loadingPatientTracker: LoadingStatus;
}

const initialReportingState: IReportingState = {
    reportValidationErrors: [],
    reportProperties: {},
    loading: LoadingStatus.Idle,
    loadingSelectedReport: LoadingStatus.Idle,
    reportingGroups: reportingGroupsInitialState,
    clinicalNoteReports: clinicalNoteReportsInitialState,
    encounterStatus: {},
    loadingEncounterStatus: LoadingStatus.Idle,
    patientTracker: {},
    loadingPatientTracker: LoadingStatus.Idle,
};

export default initialReportingState;
