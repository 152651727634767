import { DetailsRow, IColumn, IDetailsFooterProps, SelectionMode } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { SortableDetailsList } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { TransactionAmountField, FinancialModalType } from './PostPaymentModal';
import {
    baseCheckoutProceduresDetailsListColumns,
    useCheckoutCompletedProceduresColumns,
} from '../CheckoutCompletedProceduresDetailsList';
import { useMemo } from 'react';
import { ColumnTotalsLookup, getColumnTotalsForItems } from 'utils/getColumnTotalsForItems';
import { usdCurrencyFormatter } from 'utils';

type CheckoutPaymentModalDetailsList = { billingProcedures: IBillingProcedure[]; canOverpay: boolean };

export function CheckoutPaymentModalDetailsList({ billingProcedures, canOverpay }: CheckoutPaymentModalDetailsList) {
    const checkoutCols = useCheckoutCompletedProceduresColumns();
    const columns: ISortableColumn<IBillingProcedure>[] = useMemo(
        () => [
            ...checkoutCols,
            {
                name: 'Payment',
                key: 'payment',
                onRender: (item) => (
                    <TransactionAmountField
                        feeProp="patientEstimate"
                        type={FinancialModalType.Payment}
                        billingProcedure={item}
                        canOverpay={canOverpay}
                    />
                ),
                minWidth: 145,
                maxWidth: 145,
            },
        ],
        [checkoutCols, canOverpay],
    );

    const totalsLookup = useMemo(
        () => getColumnTotalsForItems<IBillingProcedure>(billingProcedures, columns),
        [billingProcedures, columns],
    );

    return (
        <SortableDetailsList<IBillingProcedure>
            selectionMode={SelectionMode.none}
            items={billingProcedures}
            showGrid
            compact
            columns={columns}
            onRenderDetailsFooter={(props) => _onRenderDetailsFooter(props, columns.length + 1, totalsLookup)}
        />
    );
}

function _onRenderDetailsFooter(
    detailsFooterProps: IDetailsFooterProps | undefined,
    itemIndex: number,
    totalsLookup: ColumnTotalsLookup<IBillingProcedure>,
) {
    if (!detailsFooterProps) return null;

    return (
        <DetailsRow
            {...detailsFooterProps}
            itemIndex={itemIndex}
            columns={detailsFooterProps.columns.map((col) => ({ ...col, onRender: undefined }))}
            item={{}}
            groupNestingDepth={detailsFooterProps.groupNestingDepth}
            onRenderItemColumn={(item, index, column) => _renderDetailsFooterItemColumn(item, index, column, totalsLookup)}
        />
    );
}

const _renderDetailsFooterItemColumn = (
    item: IBillingProcedure,
    index: number | undefined,
    column: IColumn | undefined,
    totalsLookup: ColumnTotalsLookup<IBillingProcedure>,
) => {
    if (column?.key === 'procedureDescription') return <b>Totals:</b>;
    if (
        !column ||
        baseCheckoutProceduresDetailsListColumns.slice(0, -1).findIndex((col) => column.key === col.key) > -1 ||
        column.key === 'payment'
    )
        return null;
    const value = totalsLookup[column.fieldName as keyof IBillingProcedure] ?? 0;
    const formattedValue = usdCurrencyFormatter.format(value);
    return <b title={formattedValue}>{formattedValue}</b>;
};
