import { getCode } from '@fluentui/keyboard-key';
import { IColumn, KeyCodes, SearchBox, SelectionMode, Stack, TextField } from '@fluentui/react';
import { BenefitPlanInsuranceClassName } from 'api/models/benefit-plan.model';
import { IPatientEligibility, IPatientInsurance } from 'api/models/patient.model';
import { ITenantPayer } from 'api/models/payer.model';
import { Field, Section, SortableDetailsList } from 'components';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { IValidationError, getValidationError, validateErrorMessage } from 'hooks/useValidation';
import TaskFieldLabel from 'pages/Scheduling/components/Checkin/TaskFieldLabel';
import { KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { filteredInsuranceList } from 'state/slices/edit-patient/edit-patient.selectors';
import { benefitPlanCategories, benefitPlanData } from 'state/slices/tenant/benefit-plan.slice';
import { filteredTenantPayersList, selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';
import { TaskType } from 'state/task-management/taskManagement.actions';
import { selectInsuranceFinancialInfoByTaskTypeLookup } from 'state/task-management/taskManagement.slice';
import { classicDateOnly } from 'utils/dateOnly';

type Props = {
    insurance?: IPatientInsurance;
    newInsurancePropChange: (path: keyof IPatientInsurance, value: unknown) => void;
    setInsurance: (insurance: IPatientInsurance) => void;
    isNew: boolean;
    validationErrors: IValidationError[];
};

export const insuranceIndexToPayerType: Record<number, string> = {
    0: 'Primary',
    1: 'Secondary',
    2: 'Tertiary',
    3: 'Quaternary',
};

function InsuranceProperties({ insurance, newInsurancePropChange, isNew, setInsurance, validationErrors }: Props): JSX.Element {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const payers = useSelector(filteredTenantPayersList);
    const payersLookup = useSelector(selectTenantPayersData);

    const benefitPlan = useSelector(benefitPlanCategories);
    const _benefitPlanData = useSelector(benefitPlanData);
    const _filteredInsuranceList = useSelector(filteredInsuranceList);
    const insuranceTasks = useSelector(selectInsuranceFinancialInfoByTaskTypeLookup);
    const currentInsuranceTasks = insurance ? insuranceTasks[insurance.id] : {};

    const { results, onSearch, search, onSearchChange, setSearch, setResults } = useFuseSearch({
        list: payers,
        fuseOptions: { keys: ['name', 'displayName', 'street1', 'payerId', 'phone'], threshold: 0.2 },
    });

    const onItemInvoked = (item?: ITenantPayer) => {
        if (item && insurance) {
            let majorBenefits: number | undefined = undefined;
            let basicBenefits: number | undefined = undefined;
            let preventativeBenefits: number | undefined = undefined;

            if (_benefitPlanData && item.benefitPlanId) {
                const insuranceClasses = _benefitPlanData[item.benefitPlanId]?.insuranceClasses ?? [];
                const preventative = insuranceClasses.find(
                    (insClass) => insClass.displayName === BenefitPlanInsuranceClassName.Preventative,
                );
                const major = insuranceClasses.find((insClass) => insClass.displayName === BenefitPlanInsuranceClassName.Major);
                const basic = insuranceClasses.find((insClass) => insClass.displayName === BenefitPlanInsuranceClassName.Basic);

                preventativeBenefits = preventative?.percentage ? +preventative.percentage : undefined;
                majorBenefits = major?.percentage ? +major.percentage : undefined;
                basicBenefits = basic?.percentage ? +basic.percentage : undefined;
            }

            const newInsuranceEligibility: IPatientEligibility = {
                ...insurance.eligibility,
                majorBenefits,
                basicBenefits,
                preventativeBenefits,
            };

            setInsurance({
                ...insurance,
                insuranceId: item.id,
                planId: item.benefitPlanId ?? '',
                eligibility: newInsuranceEligibility,
            });
            setSearch('');
            setResults([]);
        }
    };

    const _columns: IColumn[] = [
        {
            key: 'specialty',
            minWidth: 100,
            maxWidth: 100,
            name: 'Specialty',
            fieldName: 'specialty',
        },
        {
            key: 'name',
            minWidth: 200,
            name: 'Description',
            onRender: (item?: ITenantPayer) => {
                if (item) {
                    return <EditDetailsColumn title={item?.name ?? ''} editOnClick={() => onItemInvoked(item)} />;
                }
            },
            fieldName: 'name',
        },
        {
            key: 'payerId',
            minWidth: 100,
            maxWidth: 100,
            name: 'Payer Id',
            fieldName: 'payerId',
        },
        {
            key: 'street1',
            minWidth: 200,
            maxWidth: 200,
            name: 'Address',
            fieldName: 'street1',
        },
        {
            key: 'displayName',
            minWidth: 150,
            maxWidth: 150,
            name: 'Display Name',
            fieldName: 'displayName',
        },
        {
            key: 'phone',
            minWidth: 100,
            maxWidth: 100,
            name: 'Phone',
            fieldName: 'phone',
        },
    ];

    const payerPriorityName = () => {
        const indexOfActivePayer = _filteredInsuranceList.findIndex((ins) => ins.id === insurance?.id);

        if (indexOfActivePayer !== undefined && indexOfActivePayer > -1 && indexOfActivePayer <= 3) {
            return insuranceIndexToPayerType[indexOfActivePayer] ?? '';
        }
    };

    const getInsuranceName = insurance?.insuranceId ? payersLookup[insurance.insuranceId]?.name : "";

    const _handleKeyDown = (ev?: KeyboardEvent<HTMLInputElement>) => {
        if (ev?.code) {
            const key = getCode(ev.code);
            if (key === KeyCodes.down) {
                setResults(payers.filter((payer) => payer.isQuickPick) as ITenantPayer[]);
            }
        }
        return ev;
    };

    const validInput = (value?: string): boolean => {
        const res = value ? /^[A-Za-z0-9]+$/.test(value) : true;
        return res;
    };

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            {isNew ? (
                <Stack>
                    <Stack.Item grow>
                        <SearchBox
                            value={search}
                            placeholder="Search carrier: name, phone, or address (down arrow for quickpicks)"
                            onChange={onSearchChange}
                            onSearch={onSearch}
                            onKeyDown={_handleKeyDown}
                        />
                        {results.length ? (
                            <>
                                <Section heading={'Quickpicks'}>
                                    <SortableDetailsList<ITenantPayer>
                                        items={results.filter((res) => res.isQuickPick) || []}
                                        columns={_columns}
                                        selectionMode={SelectionMode.none}
                                        initialSortDirection={['asc']}
                                        onItemInvoked={onItemInvoked}
                                        compact
                                        sortOnMount={true}
                                        sortColumns={['Specialty']}
                                    />
                                </Section>
                                <Section heading={'Payers'}>
                                    <SortableDetailsList<ITenantPayer>
                                        items={results.filter((res) => !res.isQuickPick) || []}
                                        columns={_columns}
                                        selectionMode={SelectionMode.none}
                                        initialSortDirection={['asc']}
                                        onItemInvoked={onItemInvoked}
                                        compact
                                        sortOnMount={true}
                                        sortColumns={['Specialty']}
                                    />
                                </Section>
                            </>
                        ) : null}
                    </Stack.Item>
                </Stack>
            ) : null}
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                {isNew ? (
                    <Stack.Item grow>
                        <TextField
                            label="Carrier"
                            value={getInsuranceName}
                            readOnly
                            errorMessage={getValidationError(validationErrors, 'Insurance') ? 'Carrier is required.' : undefined}
                        />
                    </Stack.Item>
                ) : null}
                {!isNew ? (
                    <Stack.Item grow>
                        <TextField label="Type" value={payerPriorityName()} readOnly required />
                    </Stack.Item>
                ) : null}
                <Stack.Item grow>
                    <Field.Dropdown
                        label="Plan"
                        placeholder="(Select Plan)"
                        options={benefitPlan}
                        selectedKey={insurance?.planId}
                        onChange={(e, option) => {
                            if (option) newInsurancePropChange('planId', option?.key);
                        }}
                    />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <TextField
                        label="Member ID"
                        maxLength={100}
                        autoComplete="off"
                        value={insurance?.memberId}
                        required
                        onChange={(e, value) => {
                            if (validInput(value)) newInsurancePropChange('memberId', value);
                        }}
                        errorMessage={getValidationError(validationErrors, 'Member Id') ? 'Member Id is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Group Number"
                        maxLength={100}
                        autoComplete="off"
                        value={insurance?.groupNumber}
                        onChange={(e, value) => {
                            if (validInput(value)) newInsurancePropChange('groupNumber', value);
                        }}
                        onRenderLabel={(data) => (
                            <TaskFieldLabel
                                label={data?.label}
                                lookup={currentInsuranceTasks}
                                taskType={TaskType.patientInsuranceGroupNumberVerification}
                            />
                        )}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Date
                        label="Effective Date"
                        autoComplete="off"
                        value={insurance?.effectiveDate ? classicDateOnly(insurance?.effectiveDate) : ''}
                        onChange={(e, value) => newInsurancePropChange('effectiveDate', value)}
                        required
                        hasDatePicker
                        errorMessage={validateErrorMessage(getValidationError(validationErrors, 'Effective Date'))}
                        onRenderLabel={(data) => (
                            <TaskFieldLabel
                                label={data?.label}
                                lookup={currentInsuranceTasks}
                                taskType={TaskType.patientFinancialEffectiveDate}
                                required
                            />
                        )}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Date
                        label="Expiration Date"
                        autoComplete="off"
                        value={insurance?.expirationDate ? classicDateOnly(insurance?.expirationDate) : ''}
                        onChange={(e, value) => newInsurancePropChange('expirationDate', value)}
                        hasDatePicker
                        errorMessage={validateErrorMessage(getValidationError(validationErrors, 'Expiration Date'))}
                    />
                </Stack.Item>
            </Stack>
        </Stack>
    );
}

export default InsuranceProperties;
