import { SerializedError } from '@reduxjs/toolkit';
import IEncounterClaimSummary from 'api/models/encounter-claim-summary.model';
import { IEncounterSummary } from 'api/models/encounter-ledger.model';
import { LoadingStatus } from 'interfaces/loading-statuses';

export type LedgerClaimHistoryState = {
    encounterNoteModalOpen: boolean;
    selectedEncounterSummary?: IEncounterSummary;
    encounterClaimSummary?: IEncounterClaimSummary;
    encounterClaimSummaryError?: SerializedError;
    loadingEncounterClaimSummary: LoadingStatus;
};

const initialClaimHistoryState: LedgerClaimHistoryState = {
    encounterNoteModalOpen: false,
    loadingEncounterClaimSummary: LoadingStatus.Idle,
};

export default initialClaimHistoryState;
