import { Pivot, PivotItem, Stack, mergeStyles, Icon, TooltipHost } from '@fluentui/react';
import { ActivityPage } from 'components';
import { usePatientId, useTenantId } from 'hooks';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { PatientDetailsBanner, AccessDenied } from 'pages/components';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { matchPath } from 'react-router';
import { getLedgerTotalsView, handleLedgerCalculationUpdate } from 'state/slices/ledger/ledger.actions';
import { getLocationsOfCare } from 'state/slices/scheduling/scheduling.slice';
import OverallLedgerTotals from './components/OverallLedgerTotals';
import PaymentsAndAdjustments from './PaymentsAndAdjustments/PaymentsAndAdjustments';
import MakePaymentAndAdjustment from './PaymentsAndAdjustments/MakePaymentAndAdjustment';
import {
    cleanupSignedTreatmentPlan,
    cleanupSummaryLedgerState,
    setCurrentBillingProcedures,
} from 'state/slices/ledger/ledger.slice';
import TreatmentPlanCreditAndUAC from './TreatmentPlanCreditAndUAC/TreatmentPlanCreditAndUAC';
import AddTreatmentPlanPrepayment from './TreatmentPlanCreditAndUAC/AddTreatmentPlanPrepayment';
import AddUnappliedCredit from './TreatmentPlanCreditAndUAC/AddUnappliedCredit';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import {
    getPatientMostRecentTreatmentPlanCreditView,
    getPatientTreatmentPlanCreditUnappliedPayments,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.actions';
import LedgerView from './LedgerView/LedgerView';
import AdjustmentHistory from './AdjustmentHistory/AdjustmentHistory';
import ClaimHistoryDetail from './ClaimHistory/ClaimHistoryDetail';
import { signalRGroupSubscriptionService, SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import ReportModal from 'pages/Reporting/ReportModal';
import { FeatureFlag, selectActiveFeatureFlagsLookup } from 'state/slices/tenant/feature-flags.slice';
import UACDistribution from './UACDistribution/UACDistribution';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { SignalRGroup } from 'hooks/signalr/signalRGroupSubscriptionService';

const LedgerPivotStyles = mergeStyles({
    overflowX: 'auto',
    overflowY: 'hidden',
});

export function useHasClaimsHistoryAccess() {
    const [_, isGlobalAdmin] = usePermissions();
    const featureFlags = useSelector(selectActiveFeatureFlagsLookup);

    const hasClaimsHistoryAccess = useMemo(() => {
        if (isGlobalAdmin) return true;
        return !!featureFlags.has(FeatureFlag.ClaimHistory);
    }, [isGlobalAdmin, featureFlags]);

    return hasClaimsHistoryAccess;
}

function Ledger(): JSX.Element {
    const dispatch = useAppDispatch();
    const patientId = usePatientId();
    const tenantId = useTenantId();

    const { path, url } = useRouteMatch();
    const { pathname } = useLocation();
    const { push } = useHistory();

    const { registerSignalRMessage } = useSignalR();

    const [hasLedger] = usePermissions([DentalPermissions.Ledger]);
    const hasClaimsHistoryAccess = useHasClaimsHistoryAccess();

    useEffect(() => {
        if (!hasLedger || !patientId) return;

        const _getMostRecentTP = dispatch(getPatientMostRecentTreatmentPlanCreditView({ patientId, tenantId }));
        //Single call to get ledger totals view
        const _getLedgerTotalsView = dispatch(getLedgerTotalsView({ patientId, tenantId }));
        const _getPatientTPCreditUnappliedPayments = dispatch(
            getPatientTreatmentPlanCreditUnappliedPayments({ patientId, tenantId }),
        );

        dispatch(getLocationsOfCare({ tenantId }));

        registerSignalRMessage(SignalRMessage.LedgerCalculationUpdate, (data) =>
            handleLedgerCalculationUpdate(data, tenantId, patientId),
        );

        return () => {
            dispatch(setCurrentBillingProcedures([]));
            dispatch(cleanupSummaryLedgerState());
            dispatch(cleanupSignedTreatmentPlan());

            //Cancel api calls on comp unload
            _getMostRecentTP.abort('CancelMostRecentTPPageUnloaded');
            _getLedgerTotalsView.abort('CancelLedgerTotalsViewPageUnloaded');
            _getPatientTPCreditUnappliedPayments.abort('CancelTPCreditUnappliedPaymentsPageUnloaded');

            signalRGroupSubscriptionService.unsubscribeFromGroups(tenantId, [SignalRGroup.Clinical]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, tenantId, dispatch]);

    function getSelectedKey() {
        //order of this matters...
        const paths = [
            `${path}/patient-payments-and-adjustments`,
            `${path}/treatment-plan-prepayment-uac`,
            `${path}/payment-history`,
            `${path}/adjustment-history`,
            path,
        ];

        const match = matchPath(pathname, { path: paths, exact: false });
        if (match) return match.url;
        return '';
    }

    return (
        <ActivityPage disableOverflow title="Financial Ledger">
            <ReportModal />
            <PatientDetailsBanner />
            {hasLedger ? (
                <Stack style={{ padding: 10 }} grow>
                    <Stack grow tokens={{ childrenGap: 12 }} wrap horizontal>
                        <div className={LedgerPivotStyles}>
                            <Pivot
                                onLinkClick={(item) => {
                                    if (item?.props?.itemKey) {
                                        push(item.props.itemKey);
                                    }
                                }}
                                styles={{ link: { paddingLeft: 5, paddingRight: 5, margin: 0, height: 40 } }}
                                linkFormat={'links'}
                                selectedKey={getSelectedKey()}
                            >
                                <PivotItem
                                    onRenderItemLink={(props) => (
                                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                                            <span>{props?.headerText}</span>
                                            <TooltipHost content="Displays encounters that have a checked-out status. Attestation is not required.">
                                                <Icon iconName="info" />
                                            </TooltipHost>
                                        </Stack>
                                    )}
                                    key={url}
                                    itemKey={url}
                                    headerText={`Financial Ledger${hasClaimsHistoryAccess ? '/Claim HX' : ''}`}
                                />
                                <PivotItem
                                    onRenderItemLink={(props) => (
                                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                                            <span>{props?.headerText}</span>
                                            <TooltipHost content="Displays encounters with an attested chart note. Checkout is not required. Allows for payments/adjustments at the earliest point. The encounter's patient balance is an estimate unless checked out.">
                                                <Icon iconName="info" />
                                            </TooltipHost>
                                        </Stack>
                                    )}
                                    key={`${url}/patient-payments-and-adjustments`}
                                    itemKey={`${url}/patient-payments-and-adjustments`}
                                    headerText="Patient Payments and Adjustments"
                                />
                                <PivotItem
                                    key={`${url}/treatment-plan-prepayment-uac`}
                                    itemKey={`${url}/treatment-plan-prepayment-uac`}
                                    headerText="Treatment Plan Prepayment and UAC"
                                />
                                <PivotItem
                                    key={`${url}/payment-history`}
                                    itemKey={`${url}/payment-history`}
                                    headerText="Payment History"
                                />
                                <PivotItem
                                    key={`${url}/adjustment-history`}
                                    itemKey={`${url}/adjustment-history`}
                                    headerText="Adjustment History"
                                />
                            </Pivot>
                        </div>
                        <Stack grow horizontalAlign="end">
                            <OverallLedgerTotals />
                        </Stack>
                    </Stack>
                    <Switch>
                        {/* Looping and creating these routes dynamically causes a hooks error :( */}
                        <Route path={`${path}/patient-payments-and-adjustments/make-payment-adjustment`}>
                            <MakePaymentAndAdjustment />
                        </Route>
                        <Route path={`${path}/treatment-plan-prepayment-uac/add-treatment-plan-prepayment`}>
                            <AddTreatmentPlanPrepayment />
                        </Route>
                        <Route path={`${path}/treatment-plan-prepayment-uac/add-unapplied-credit`}>
                            <AddUnappliedCredit />
                        </Route>
                        <Route path={`${path}/claim-history/:claimHistoryEncounterId`}>
                            {hasClaimsHistoryAccess ? <ClaimHistoryDetail /> : <Redirect to={url} />}
                        </Route>
                        <Route path={`${path}/patient-payments-and-adjustments`}>
                            <PaymentsAndAdjustments />
                        </Route>
                        <Route exact={false} path={`${path}/treatment-plan-prepayment-uac/uac-distribution/:uacTransactionId`}>
                            <UACDistribution />
                        </Route>
                        <Route path={`${path}/treatment-plan-prepayment-uac`}>
                            <TreatmentPlanCreditAndUAC />
                        </Route>
                        <Route path={`${path}/payment-history`}>
                            <PaymentHistory />
                        </Route>
                        <Route path={`${path}/adjustment-history`}>
                            <AdjustmentHistory />
                        </Route>
                        <Route exact path={path}>
                            <LedgerView />
                        </Route>
                    </Switch>
                </Stack>
            ) : (
                <AccessDenied />
            )}
        </ActivityPage>
    );
}

export default Ledger;
