import { DayOfWeek, IDropdownOption } from '@fluentui/react';
import IOperatory, { TimeTableRange } from 'api/models/Scheduling/operatory.model';
import { format } from 'date-fns';
import { Dictionary, flatten, forEach, map, max, min, orderBy, sortBy, uniq, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { getDayOfWeekString } from 'utils/getDayOfWeekString';
import { isDateBetween } from 'utils/isDateBetween';
import {
    selectSelectedLOC,
    selectSelectedDate,
    selectSelectedAppointmentData,
    selectSelectedCheckoutUpcomingPhases,
    selectFilteredOperatories,
} from '../../scheduling/scheduling.selectors';
import { classicDateOnly } from 'utils/dateOnly';

//Operatories
export const selectLookupOperatories = (state: RootState, tenantId: string): Dictionary<IOperatory> =>
    state.lookups.tenants?.[tenantId]?.Operatories?.items ?? {};

export const selectOperatoriesAsList = createSelector(selectLookupOperatories, (lookup) =>
    map(lookup)?.filter(isOperatoryNotDeleted),
);

export const toggleActiveOperatory = createSelector(selectLookupOperatories, (lookup) => {
    return map(lookup);
});

export const getOperatoryValidPeriodForCurrentDate = (operatory: IOperatory, selectedDate: Date) => {
    return operatory.periods?.find((p) => {
        return isDateBetween({
            dateToCheck: selectedDate.toString(),
            start: convertDashedDateString(p.startDate),
            end: convertDashedDateString(p.endDate),
        });
    });
};

export function isOperatoryNotDeleted(operatory: IOperatory) {
    return !operatory.isDeleted;
}
export function operatoryMatchesSelectedLOC(operatory: IOperatory, locationOfCareId?: string) {
    return operatory.locationOfCareId === locationOfCareId;
}

export function isOperatoryValid(operatory: IOperatory, date: Date, locationOfCareId?: string) {
    return (
        getOperatoryValidPeriodForCurrentDate(operatory, date) &&
        isOperatoryNotDeleted(operatory) &&
        operatoryMatchesSelectedLOC(operatory, locationOfCareId)
    );
}

export const selectOperatoryTagOptions = createSelector(selectOperatoriesAsList, (ops) => {
    return sortBy(
        uniq(flatten(ops.map((op) => op.tags ?? []))).map((tag) => {
            const option: IDropdownOption = {
                key: tag,
                text: tag,
            };
            return option;
        }),
        'key',
        'asc',
    );
});

/**
 * Build a spelector that builds all operatory options for select ops dropdown in scheduling.
 *
 * Individual ops. (Ids of ops) ONLY IF THERE ARE NO TAGS DO WE WANT THESE
 * Tags (Tags on ops)
 */
function filterOperatoriesByLocationOfCareId(operatories: IOperatory[], locationOfCareId: string | undefined) {
    return sortBy(
        operatories?.length ? operatories.filter((op) => operatoryMatchesSelectedLOC(op, locationOfCareId)) : [],
        'displayOrder',
        ['desc'],
    );
}

export const selectOperatoriesByLOC = createSelector(selectOperatoriesAsList, selectSelectedLOC, (operatories, loc) => {
    return filterOperatoriesByLocationOfCareId(operatories, loc?.id);
});

export const selectOperatoriyOptionsByLOC = createSelector(selectOperatoriesByLOC, selectSelectedDate, (ops, selectedDate) => {
    const validOperatories = ops.filter((op) => getOperatoryValidPeriodForCurrentDate(op, selectedDate));
    const operatoryTags = sortBy(uniq(flatten(validOperatories.map((op) => op.tags ?? []))));
    const operatoriesWithNoTags = orderBy(
        validOperatories.filter((op) => !op.tags?.length),
        ['displayName'],
        ['asc'],
    );

    const options: IDropdownOption[] = [...operatoryTags, ...operatoriesWithNoTags].map((value) => {
        if (typeof value === 'string') {
            return { key: value, text: value };
        } else {
            return { key: value.id, text: value.displayName };
        }
    });
    return options;
});

export const selectCurrentOperatories = createSelector(
    selectOperatoriesByLOC,
    selectSelectedDate,
    (operatoriesByLoc, selectedDate) => {
        return operatoriesByLoc.filter((op) => getOperatoryValidPeriodForCurrentDate(op, selectedDate));
    },
);

// Check to see if the operatory is open today.

export function isOperatoryOpenToday(operatory: IOperatory, selectedDate: Date) {
    const dayOfWeek = getDayOfWeekString(selectedDate);
    const currentOperatory = getOperatoryValidPeriodForCurrentDate(operatory, selectedDate);
    if (currentOperatory && dayOfWeek) {
        return currentOperatory.timetables?.[dayOfWeek]?.length ? true : false;
    }
}

export const selectCurrentOperatoriesFromSelectedApptLoc = createSelector(
    selectOperatoriesAsList,
    selectSelectedAppointmentData,
    (operatories, appointment) =>
        filterOperatoriesByLocationOfCareId(operatories, appointment?.locationOfCareId).filter((op) =>
            appointment?.date ? isOperatoryOpenToday(op, new Date(classicDateOnly(appointment?.date))) : false,
        ),
);

// Dropdown options for the appointment panel.
export const selectCurrentOperatoriesAppointmentPanelOptions = createSelector(
    selectCurrentOperatoriesFromSelectedApptLoc,
    (currentOperatories) => {
        if (!currentOperatories.length) return [];

        const options: IDropdownOption[] = sortBy(
            currentOperatories.map((op) => {
                return { key: op.id, text: op.displayName };
            }) ?? [],
            'key',
            'asc',
        );

        return options;
    },
);

export const selectCurrentFilteredOperatories = (state: RootState, tenantId: string, locationOfCareId: string) => {
    const operatories = selectCurrentOperatories(state, tenantId); //all ops for current loc

    const operatoryTagsAndIds = selectFilteredOperatories(state, tenantId, locationOfCareId) ?? []; // id string | tag string

    //For filtering by operatory id.
    const filteredOperatoryIdsByLOC = sortBy(
        operatoryTagsAndIds?.filter((value) => {
            return operatories.findIndex((op) => op.id === value) > -1;
        }) ?? [],
        'key',
        'asc',
    );

    // For filtering by tags
    const filteredOperatoryIdsWithTagsByLOC = operatories
        .filter((op) => op.tags?.length)
        .filter((op) => (op.tags ?? [])?.findIndex((tag) => operatoryTagsAndIds.includes(tag)) > -1);

    const filteredOperartories = [
        ...operatories.filter((op) => filteredOperatoryIdsByLOC.includes(op.id)),
        ...filteredOperatoryIdsWithTagsByLOC,
    ];

    return filteredOperartories?.length ? filteredOperartories : operatories;
};

export const selectSelectedLocationOfCare = (state: RootState) => state.scheduling.selectedLOC?.id;

export const selectCurrentOperatoriesStartAndEndTime = createSelector(
    selectCurrentOperatories,
    selectSelectedDate,
    (operatories, selectedDate) => {
        if (operatories) {
            const startTimes: Date[] = [];
            const endTimes: Date[] = [];
            const dateToUse = format(selectedDate, 'MM-dd-yyyy');
            const day = getDayOfWeekString(selectedDate);
            operatories.forEach((operatory) =>
                operatory.periods?.forEach((period) => {
                    if (period.timetables && day) {
                        const _timeTables = period.timetables[day];
                        if (_timeTables && _timeTables.length) {
                            _timeTables.forEach((item: TimeTableRange) => {
                                startTimes.push(new Date(`${dateToUse} ${item.startTime}`));
                                endTimes.push(new Date(`${dateToUse} ${item.endTime}`));
                            });
                        }
                    }
                }),
            );
            const minStartTime = min(startTimes);
            const maxEndTime = max(endTimes);
            const minTime = startTimes.length && minStartTime ? `${format(minStartTime, 'HH:mm:ss')}` : '08:00:00';
            const maxTime = endTimes.length && maxEndTime ? `${format(maxEndTime, 'HH:mm:ss')}` : '19:00:00';

            return { startTime: minTime, endTime: maxTime };
        }
        return { startTime: '08:00:00', endTime: '19:00:00' };
    },
);

export const selectCurrentOperatoriesAsResources = createSelector(
    selectCurrentFilteredOperatories,
    selectSelectedDate,
    (currentOperatories, selectedDate) => {
        return currentOperatories
            .map((op) => {
                const currentPeriod = getOperatoryValidPeriodForCurrentDate(op, selectedDate);

                const dayOfWeek = getDayOfWeekString(selectedDate);

                const dayOfWeekNumber: Dictionary<DayOfWeek> = {
                    sunday: DayOfWeek.Sunday,
                    monday: DayOfWeek.Monday,
                    tuesday: DayOfWeek.Tuesday,
                    wednesday: DayOfWeek.Wednesday,
                    thursday: DayOfWeek.Thursday,
                    friday: DayOfWeek.Friday,
                    saturday: DayOfWeek.Saturday,
                };
                type BusinessHours = TimeTableRange & { daysOfWeek: DayOfWeek[] };
                const businessHours: BusinessHours[] = [];
                if (currentPeriod && dayOfWeek) {
                    forEach(currentPeriod.timetables, (day, dayKey) => {
                        forEach(day, (timetable) => {
                            businessHours.push({
                                daysOfWeek: [dayOfWeekNumber[dayKey]],
                                startTime: timetable.startTime,
                                endTime: timetable.endTime,
                            });
                        });
                    });
                }

                if (
                    currentPeriod?.timetables &&
                    Object.keys(currentPeriod.timetables).length &&
                    dayOfWeek &&
                    currentPeriod.timetables[dayOfWeek] &&
                    currentPeriod.timetables[dayOfWeek].length
                ) {
                    return {
                        id: op.id,
                        title: op.displayName,
                        businessHours,
                        displayOrder: op.displayOrder ?? 999,
                        displayName: op.displayName,
                    };
                }
            })
            .filter((op) => op !== undefined) as any[];
    },
);
