import { Stack, Spinner, MessageBar, Text, DefaultButton, PrimaryButton } from '@fluentui/react';
import { usdCurrencyFormatter } from 'utils';
import { useTheme } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { useSelector } from 'react-redux';
import {
    selectCheckoutLoading,
    selectCompletedProceduresAsList,
    selectCompletedProceduresTotal,
} from 'state/slices/scheduling/scheduling.selectors';
import { Section } from 'components';
import { LoadingStatus } from 'interfaces/loading-statuses';
import CheckoutCompletedProceduresDetailsList from './CheckoutCompletedProceduresDetailsList';

interface CheckoutCompletedProceduresProps {
    handleOpenPaymentModal: (procedures: IBillingProcedure[]) => void;
    patientReceipt: () => void;
}

const CheckoutCompletedProcedures = ({ handleOpenPaymentModal, patientReceipt }: CheckoutCompletedProceduresProps) => {
    const theme = useTheme();
    const completedProcedures = useSelector(selectCompletedProceduresAsList);
    const completedProceduresTotal = useSelector(selectCompletedProceduresTotal);
    const billingProceduresLoading = useSelector(selectCheckoutLoading) === LoadingStatus.Pending;

    return (
        <Section
            heading={'Procedures Completed Today'}
            headingRightContent={
                <>
                    <DefaultButton
                        disabled={!completedProcedures.length}
                        onClick={() => handleOpenPaymentModal(completedProcedures)}
                    >
                        Enter Payment
                    </DefaultButton>
                    <PrimaryButton onClick={patientReceipt}>Print Receipt</PrimaryButton>
                </>
            }
        >
            <Stack tokens={{ childrenGap: 10 }}>
                {billingProceduresLoading ? (
                    <Spinner label="Loading procedures..." />
                ) : (
                    <>
                        {!billingProceduresLoading && completedProcedures.length === 0 ? (
                            <MessageBar>No procedures completed today.</MessageBar>
                        ) : (
                            <CheckoutCompletedProceduresDetailsList />
                        )}
                    </>
                )}
                <Stack
                    tokens={{ childrenGap: 10 }}
                    horizontal
                    horizontalAlign="end"
                    styles={{ root: { background: theme.palette.white, padding: 5 } }}
                >
                    <Text>Patient Total</Text>
                    <Text>{usdCurrencyFormatter.format(completedProceduresTotal)}</Text>
                </Stack>
            </Stack>
        </Section>
    );
};

export default CheckoutCompletedProcedures;
