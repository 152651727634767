import {
    Checkbox,
    Dropdown,
    IDropdownOption,
    List,
    MessageBar,
    MessageBarType,
    Persona,
    PersonaSize,
    Separator,
    Shimmer,
    ShimmerElementType,
    Stack,
    Text,
    TextField,
} from '@fluentui/react';
import { IEncounterCorrespondence } from 'api/models/encounter-claim-summary.model';
import { EncounterStatus } from 'api/models/encounter.model';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { useUserIdentity } from 'components/UserDisplayName';
import { format } from 'date-fns';
import { useTenantId } from 'hooks';
import { SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { orderBy } from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEncounterCorrespondenceEncounterWorkList,
    toggleEncounterWorkListItemIsRcm,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import {
    selectEncounterWorkListNoteModalEncounterCorrespondence,
    selectEncounterWorkListNoteModalNote,
    selectEncounterWorkListNoteModalAction,
    selectEncounterWorkListNoteModalSaving,
    selectEncounterWorkListNoteModalLoading,
    selectEncounterWorklistNoteModalSavingIsRCM,
    selectEncounterWorklistNoteModalMessageBar,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.selectors';
import {
    EncounterWorkList,
    EncounterWorkListNoteModalAction,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import {
    selectWorkListEditItem,
    selectWorkListReadOnly,
    selectIsCurrentWorkListEditEncounterWorkList,
    selectCurrentWorkList,
    selectWorkListSaving,
} from 'state/slices/admin-huddle/worklists/worklist.selectors';
import {
    cleanupEncounterWorkListMessageBar,
    insertEncounterWorkListCorrespondence,
    setEncounterWorkListModalAction,
    setEncounterWorkListModalNote,
} from 'state/slices/admin-huddle/worklists/worklist.slice';
import ReapproveEncounterBatchField from './ReapproveEncounterBatchField';

const shimmerElements = [
    { type: ShimmerElementType.circle },
    { type: ShimmerElementType.gap, width: '2%' },
    { type: ShimmerElementType.line },
];
/** Shimmer component for when encounter correspondence is loading */
function CorrespondenceNoteShimmer() {
    return (
        <>
            <Separator />
            <Stack tokens={{ childrenGap: 5 }} grow>
                <Shimmer shimmerElements={shimmerElements} />
                <Shimmer />
            </Stack>
            <Separator />
        </>
    );
}

export type EncounterCorrespondenceNotesProps = { encounterId?: string; aboveNoteContent?: JSX.Element };

export default function EncounterCorrespondenceNotes(props: EncounterCorrespondenceNotesProps) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const { registerSignalRMessage } = useSignalR();

    //Data
    const encounterView = useSelector(selectWorkListEditItem<IWorkListEncounterView>);
    const correspondence = useSelector(selectEncounterWorkListNoteModalEncounterCorrespondence);
    //Note modal inputs
    const note = useSelector(selectEncounterWorkListNoteModalNote);
    const action = useSelector(selectEncounterWorkListNoteModalAction);
    // Booleans
    const isEditEncounterWorkList = useSelector(selectIsCurrentWorkListEditEncounterWorkList);
    const currentWorkList = useSelector(selectCurrentWorkList);
    const isOpenBilledEncounterWorkList =
        currentWorkList === EncounterWorkList.BilledInsurance || currentWorkList === EncounterWorkList.BilledPatient;
    const readOnly = useSelector(selectWorkListReadOnly);
    // Loading/Saving
    const saving = useSelector(selectEncounterWorkListNoteModalSaving);
    const savingWorkList = useSelector(selectWorkListSaving);
    const savingIsRCM = useSelector(selectEncounterWorklistNoteModalSavingIsRCM);
    const { messageBarMessage, messageBarType } = useSelector(selectEncounterWorklistNoteModalMessageBar);

    const loading = useSelector(selectEncounterWorkListNoteModalLoading);

    const isLoading = loading === LoadingStatus.Pending;
    const hasError = loading === LoadingStatus.Failed;
    const isSaving = saving === LoadingStatus.Pending || savingWorkList === LoadingStatus.Pending;

    const isRecalculationHold = encounterView?.encounter?.isRecalculationHold;

    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const encounterId = props.encounterId ?? encounterView?.encounter?.id;

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.UpdatedEncounterCorrespondence, (data, args) => {
            dispatch(insertEncounterWorkListCorrespondence({ data, encounterId: args[0] }));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (encounterId) {
            dispatch(getEncounterCorrespondenceEncounterWorkList({ tenantId, encounterId }));
        }
    }, [encounterId, tenantId, dispatch]);

    const _onChange = (e: FormEvent, value?: string) => {
        dispatch(setEncounterWorkListModalNote(value ?? ''));
    };

    const _toggleIsRCM = () => {
        dispatch(toggleEncounterWorkListItemIsRcm({ tenantId }));
    };

    const disableSortDropdown = isLoading || !correspondence?.length || hasError;

    function getDestinationDropdownOptions() {
        const options: IDropdownOption[] = [
            {
                key: '',
                text: '(Select Destination/Action)',
            },
        ];

        if (
            isOpenBilledEncounterWorkList ||
            currentWorkList === EncounterWorkList.Denials ||
            currentWorkList === EncounterWorkList.ReadyToRebill
        ) {
            return [
                ...options,
                {
                    key: EncounterStatus.CorrectionAmend,
                    text: 'Send for Provider Correction & Addendum',
                },
                {
                    key: EncounterStatus.CorrectionsNeeded,
                    text: 'Send for Non-Provider Correction & Reapproval',
                },
            ];
        }

        if (currentWorkList === EncounterWorkList.ReadyToRebillOnHold) {
            return [
                ...options,
                {
                    key: EncounterStatus.CorrectionsNeeded,
                    text: 'Send for Non-Provider Correction & Reapproval',
                },
            ];
        }

        return [
            ...options,
            {
                key: EncounterStatus.CorrectionsCompleted,
                text: 'Send to Ready to Reapprove',
            },
            {
                key: EncounterStatus.ReBillOnHold,
                text: 'Send for Clarification',
            },
            {
                key: EncounterStatus.CorrectionAmend,
                text: 'Send for Provider Correction & Addendum',
            },
            {
                key: 'Rebill',
                text: 'Reapprove Encounter',
            },
            {
                key: EncounterStatus.Billed,
                text: 'Abort',
            },
        ];
    }

    return (
        <Stack grow>
            <Stack tokens={{ childrenGap: 5 }} grow>
                <Dropdown
                    label="Sort Notes"
                    options={[
                        {
                            key: 'desc',
                            text: 'Newest to Oldest',
                        },
                        {
                            key: 'asc',
                            text: 'Oldest to Newest',
                        },
                    ]}
                    disabled={disableSortDropdown}
                    selectedKey={sortDirection}
                    onChange={(ev, option) => {
                        if (option?.key) {
                            setSortDirection(option.key as 'asc' | 'desc');
                        }
                    }}
                />
                <Stack style={{ maxHeight: 300, minWidth: 400, overflow: 'auto' }} tokens={{ childrenGap: 5 }}>
                    {correspondence?.length && !hasError && <Separator />}
                    {hasError ? (
                        <MessageBar messageBarType={MessageBarType.error}>Something went wrong.</MessageBar>
                    ) : correspondence?.length && !isLoading ? (
                        <List
                            items={orderBy(correspondence, ['date'], sortDirection)}
                            onRenderCell={(item) => {
                                if (!item) return null;
                                return <EncounterCorrespondenceNote correspondence={item} />;
                            }}
                        />
                    ) : isLoading ? (
                        <CorrespondenceNoteShimmer />
                    ) : (
                        <MessageBar>No encounter notes exist.</MessageBar>
                    )}
                </Stack>
            </Stack>
            {!readOnly && (
                <>
                    <Stack tokens={{ childrenGap: 10 }}>
                        {messageBarMessage && messageBarType ? (
                            <Stack.Item>
                                <MessageBar
                                    onDismiss={() => {
                                        dispatch(cleanupEncounterWorkListMessageBar());
                                    }}
                                    messageBarType={messageBarType}
                                >
                                    {messageBarMessage}
                                </MessageBar>
                            </Stack.Item>
                        ) : null}
                        {isEditEncounterWorkList && (
                            <Stack.Item>
                                <Checkbox
                                    disabled={savingIsRCM === LoadingStatus.Pending}
                                    label="Billing Services"
                                    checked={encounterView?.isRcm}
                                    onChange={_toggleIsRCM}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                    {props.aboveNoteContent ?? props.aboveNoteContent}
                    <Stack tokens={{ childrenGap: 10 }} grow horizontal>
                        {(isEditEncounterWorkList ||
                            currentWorkList === EncounterWorkList.BilledInsurance ||
                            currentWorkList === EncounterWorkList.BilledPatient ||
                            currentWorkList === EncounterWorkList.Denials ||
                            currentWorkList === EncounterWorkList.ReadyToRebill ||
                            currentWorkList === EncounterWorkList.ReadyToRebillOnHold) && (
                                <Stack.Item grow>
                                    <Dropdown
                                        styles={{ root: { minWidth: 280 } }}
                                        options={getDestinationDropdownOptions()}
                                        onChange={(ev, option) => {
                                            if (option?.key !== undefined) {
                                                dispatch(
                                                    setEncounterWorkListModalAction(option.key as EncounterWorkListNoteModalAction),
                                                );
                                            }
                                        }}
                                        disabled={isSaving || isRecalculationHold}
                                        selectedKey={action ?? ''}
                                        placeholder="Select a Destination/Action"
                                        label="Destination/Action"
                                    />
                                </Stack.Item>
                            )}
                        {action === 'Rebill' && <ReapproveEncounterBatchField />}
                    </Stack>
                    {isRecalculationHold && (
                        <MessageBar messageBarType={MessageBarType.info}>
                            This encounter cannot be sent to a destination or have actions taken on it until the patient insurance
                            balance is $0.00.
                        </MessageBar>
                    )}
                    <TextField disabled={isSaving} multiline label="Note" required value={note} onChange={_onChange} />
                </>
            )}
        </Stack>
    );
}

function EncounterCorrespondenceNote({ correspondence }: { correspondence: IEncounterCorrespondence }) {
    const { firstName, lastName, userName } = useUserIdentity({ userId: correspondence.user });

    return (
        <Stack className="ms-motion-slideUpIn" tokens={{ childrenGap: 5 }}>
            <Stack horizontal horizontalAlign="space-between">
                <Persona
                    size={PersonaSize.size24}
                    onRenderInitials={() => (
                        <>
                            {firstName && lastName ? (
                                <span>
                                    {(firstName ?? '')[0]}
                                    {(lastName ?? '')[0]}
                                </span>
                            ) : (
                                <span>{userName[0]?.toUpperCase()}</span>
                            )}
                        </>
                    )}
                    text={userName}
                />
                <Text variant="small">
                    {correspondence.date ? format(new Date(correspondence.date), 'MM/dd/yyyy h:mm a') : ''}
                </Text>
            </Stack>
            <Stack>
                <Text>{correspondence.message ?? ''}</Text>
                <Separator />
            </Stack>
        </Stack>
    );
}
