import React, { useState, useEffect, useMemo } from 'react';
import {
    Panel,
    PrimaryButton,
    Stack,
    SelectionMode,
    PanelType,
    Selection,
    ISelection,
    IObjectWithKey,
    MessageBar,
    MessageBarType,
    Text,
} from '@fluentui/react';
import { SortableDetailsList } from 'components';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'interfaces/route-params';
import { useSelector } from 'hooks';
import { selectReportingState } from 'state/slices/reporting/reporting.selectors';
import { useAppDispatch } from 'hooks/useAppDispatch';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { ReportType } from 'api/models/embed-report.model';
import dentalApi from 'api/dental.api';
import { ChartTreatmentPlanStatus, IChartTreatmentPlan } from 'api/models/treatment-plan.model';
import { classicDateOnly } from 'utils/dateOnly';
import { allPatientEncounters } from 'state/slices/patient/patient.selectors';

interface PrintTreatmentPlanPanelProps {
    isOpen: boolean;
    onDismiss: () => void;
    treatmentPlans: IChartTreatmentPlan[];
}

const PrintTreatmentPlanPanel: React.FC<PrintTreatmentPlanPanelProps> = ({ isOpen, onDismiss }) => {
    const dispatch = useAppDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();

    const [treatmentPlans, setTreatmentPlans] = useState<IChartTreatmentPlan[]>([]);
    const [selectedTreatmentPlans, setSelectedTreatmentPlans] = useState<IChartTreatmentPlan[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

    const isLoading = loadingStatus === LoadingStatus.Pending;

    const { loadingSelectedReport: loadingPrintReport } = useSelector(selectReportingState);
    const _allPatientEncounters = useSelector(allPatientEncounters);
    const isLoadingPrintReport = loadingPrintReport === LoadingStatus.Pending;

    const _renderPresentedDate = (item?: IChartTreatmentPlan) => {
        const date = item && _allPatientEncounters.find((encounter) => encounter.id === item.encounterId)?.encounterDate;

        return (
            <Text variant="smallPlus">
                {date ? classicDateOnly(date) : item?.createdOn ? classicDateOnly(item.createdOn) : 'N/A'}
            </Text>
        );
    };

    const _renderDisplayName = (item?: IChartTreatmentPlan, index?: number) => (
        <span>Treatment Plan {index !== undefined ? index + 1 : ''}</span>
    );

    const _renderSignDate = (item?: IChartTreatmentPlan) => {
        if (item?.signedDate && item?.status === ChartTreatmentPlanStatus.Signed) {
            return <Text variant="smallPlus">{classicDateOnly(item?.signedDate)}</Text>;
        } else {
            return '';
        }
    };

    const _renderStatus = (item?: IChartTreatmentPlan) => {
        if (item?.status === ChartTreatmentPlanStatus.Signed) {
            return <Text variant="smallPlus">Signed</Text>;
        }
        if (item?.refusedToSign === true) {
            return <Text variant="smallPlus">Refused to Sign</Text>;
        } else {
            return <Text variant="smallPlus">Pending</Text>;
        }
    };

    const selection = useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    setSelectedTreatmentPlans(selection.getSelection() as IChartTreatmentPlan[]);
                },
                items: treatmentPlans,
                getKey: (item) => item.id,
            }),
        [treatmentPlans],
    );

    useEffect(() => {
        if (isOpen) {
            getTreatmentPlans().then((data) => {
                setTreatmentPlans(data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    async function getTreatmentPlans(): Promise<IChartTreatmentPlan[]> {
        setLoadingStatus(LoadingStatus.Pending);
        try {
            const { data } = await dentalApi.getChartTreatmentPlans(tenantId, patientId);
            setLoadingStatus(LoadingStatus.Completed);
            return data ?? [];
        } catch (e) {
            setLoadingStatus(LoadingStatus.Failed);
            return [];
        }
    }

    const onDismissed = () => {
        setLoadingStatus(LoadingStatus.Idle);
        setTreatmentPlans([]);
        setSelectedTreatmentPlans([]);
    };

    // const _onItemInvoked = (item?: IChartTreatmentPlan) => {
    //     if (!item) return;
    //     dispatch(reportActionLookup[ReportType.TreatmentPlan]({ tenantId, chartTreatmentPlanIds: item.id }));
    // };

    const print = () => {
        if (selectedTreatmentPlans.length > 0) {
            const selectedIds = selectedTreatmentPlans.map((plan) => plan.id);
            dispatch(reportActionLookup[ReportType.TreatmentPlan]({ tenantId, chartTreatmentPlanId: selectedIds }));
        }
    };

    return (
        <Panel
            type={PanelType.custom}
            headerText="Print Treatment Plans"
            customWidth={'50vw'}
            isOpen={isOpen}
            onDismiss={onDismiss}
            onDismissed={onDismissed}
            closeButtonAriaLabel="Close"
        >
            <Stack tokens={{ childrenGap: 10 }}>
                {loadingStatus === LoadingStatus.Completed && !treatmentPlans.length ? (
                    <MessageBar messageBarType={MessageBarType.info}>No treatment plans available to print.</MessageBar>
                ) : (
                    <div style={{ overflowY: 'auto', maxHeight: 'auto' }}>
                        <SortableDetailsList<IChartTreatmentPlan>
                            compact
                            selectionMode={SelectionMode.multiple}
                            selection={selection as unknown as ISelection<IObjectWithKey>}
                            items={treatmentPlans}
                            shimmerLines={3}
                            enableShimmer={isLoading}
                            columns={[
                                {
                                    key: 'createdOn',
                                    name: 'Presented Date',
                                    fieldName: 'createdOn',
                                    minWidth: 100,
                                    maxWidth: 130,
                                    isResizable: true,
                                    onRender: _renderPresentedDate,
                                },
                                {
                                    key: 'treatmentPlanDisplayName',
                                    name: 'Name',
                                    fieldName: 'treatmentPlanDisplayName',
                                    minWidth: 100,
                                    maxWidth: 200,
                                    isResizable: true,
                                    onRender: _renderDisplayName,
                                },
                                {
                                    key: 'status',
                                    name: 'Status',
                                    fieldName: 'status',
                                    minWidth: 100,
                                    maxWidth: 130,
                                    isResizable: true,
                                    onRender: _renderStatus,
                                },
                                {
                                    key: 'modifiedOn',
                                    name: 'Sign Date',
                                    fieldName: 'modifiedOn',
                                    minWidth: 120,
                                    maxWidth: 150,
                                    isResizable: true,
                                    onRender: _renderSignDate,
                                },
                            ]}
                        />
                    </div>
                )}
                <PrimaryButton
                    text={isLoadingPrintReport ? 'Loading...' : 'Print'}
                    onClick={print}
                    disabled={!selectedTreatmentPlans.length || isLoadingPrintReport}
                />
            </Stack>
        </Panel>
    );
};

export default PrintTreatmentPlanPanel;
