import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

export type ColumnTotalsLookup<T> = Partial<Record<keyof T, number>>;
export function getColumnTotalsForItems<T>(items: T[], columns: ISortableColumn<T>[]) {
    const lookup: ColumnTotalsLookup<T> = {};

    items.forEach((proc) => {
        columns.forEach((col) => {
            const key = col.fieldName ? (col.fieldName as keyof T) : undefined;

            if (key) {
                const value = col.getValueKey ? +(col.getValueKey(proc) as string) : proc[key];
                if (value !== undefined && typeof value === 'number') {
                    if (lookup[key] === undefined) {
                        lookup[key] = value;
                    } else {
                        (lookup[key] as number) += value;
                    }
                }
            }
        });
    });

    return lookup;
}
