import {
    CompoundButton,
    DirectionalHint,
    IContextualMenuListProps,
    IRenderFunction,
    ITheme,
    Label,
    memoizeFunction,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    ScrollablePane,
    SearchBox,
    Stack,
    Text,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import useChartNotes from 'hooks/store/useChartNotes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AttestSignature } from 'api/models/chart-note.model';
import { useEncounter, usePatientId, useSelector, useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SaveStatusIcon } from 'pages/components';
import useEncounterId from 'hooks/useEncounterId';
import { useDispatch } from 'react-redux';
import {
    getQuickNoteProcedures,
    getQuickNoteForms,
    getQuickNoteNextAppointment,
    fetchPAMV,
    NoteParser,
    noteParserDisplayName,
} from 'state/slices/chart-notes/quick-notes/quick-notes.actions';
import { EncounterStatus } from 'api/models/encounter.model';
import { isEqual, orderBy } from 'lodash';
import {
    selectChartNoteModified,
    selectChartNoteSaving,
    selectCurrentChartNote,
    selectCurrentClinicalNoteSignatures,
} from 'state/slices/chart-notes/chart-notes.selectors';
import { formatDistanceToNow } from 'date-fns';
import { Prompt } from 'react-router-dom';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { setCurrentChartNoteDataAndSave } from 'state/slices/chart-notes/chart-notes.actions';
import { TextEditor } from './TextEditor';
import store from 'state/store';
import getQuickNoteFragment from 'state/slices/chart-notes/quick-notes/getQuickNoteFragment';
import { usePickerPlugin } from './usePickerPlugin';
import { selectProcedureTemplatesLookup } from 'state/slices/lookups/lookups.selectors';
import { IProcedureDetail } from 'api/models/procedure-detail.model';
import { PickerSuggestionOption } from './PickerComponent';
import { ProcedureNotesTemplateFromProcedureDetail } from 'state/slices/chart-notes/quick-notes/templates/ProcedureNoteTemplates';
import { renderToStaticMarkup } from 'react-dom/server';
import useBeforeUnloadConfirmation from 'hooks/useBeforeUnloadConfirmation';
import { cleanupQuickNoteAssets } from 'state/slices/chart-notes/chart-notes.slice';

export const QUICK_NOTE_PICKER_TRIGGER_CHAR = '*';
export const PROCEDURE_TEMPLATE_PICKER_TRIGGER_CHAR = '@';
export const SAVE_NOTE_USER_PROMPT =
    'WARNING: To avoid loss of data, please save the clinical note before leaving. Are you sure you want to leave?';

function ClinicalNote(): JSX.Element {
    const theme = useTheme();
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const encounterId = useEncounterId();

    const {
        upsertChartNote,
        setClinicalNote: setCurrentNote,
        loadingCurrentNote,
        chartNoteSaveStatus,
        getChartNoteByEncounterId,
    } = useChartNotes();

    const { patientEncounter } = useEncounter();

    const currentNote = useSelector(selectCurrentChartNote);
    const chartNoteModified = useSelector(selectChartNoteModified);

    const savingClinicalNote = useSelector(selectChartNoteSaving);
    const isSaving = savingClinicalNote === LoadingStatus.Pending;

    const procedureTemplates = useSelector((state) => selectProcedureTemplatesLookup(state, tenantId));

    //Quick note picker
    const quickNotePickerOptions = useMemo(
        () => [
            { key: '', displayName: 'Add All' },
            ...Object.keys(NoteParser).map((parser) => ({
                displayName: noteParserDisplayName[parser as NoteParser],
                key: parser,
            })),
        ],
        [],
    );

    const promptUserForSave = chartNoteModified || chartNoteSaveStatus !== SyncStatus.Saved;

    useBeforeUnloadConfirmation(promptUserForSave, SAVE_NOTE_USER_PROMPT);

    const onGetQuickNoteToInsert = (key: NoteParser) => {
        const fragmentValue = getQuickNoteFragment(store.getState(), key, tenantId);
        return fragmentValue;
    };

    const quickNotePicker = usePickerPlugin({
        triggerCharacter: QUICK_NOTE_PICKER_TRIGGER_CHAR,
        suggestionOptions: quickNotePickerOptions,
        onGetSuggestionToInsert: (option) => {
            return onGetQuickNoteToInsert(option.key as NoteParser);
        },
    });

    //Procedure tempate picker
    const [procTemplateSearch, setProcTemplateSearch] = useState<string>('');
    const procedureTemplateOptions: PickerSuggestionOption<IProcedureDetail | undefined>[] = useMemo(
        () =>
            orderBy(procedureTemplates, 'code').map((p) => ({
                displayName: `${p.code}: ${p.displayName}`,
                key: p.id,
                data: p,
            })),
        [procedureTemplates],
    );

    const onGetProcedureTemplateToInsert = (option: PickerSuggestionOption<IProcedureDetail | undefined>) => {
        return renderToStaticMarkup(
            <ProcedureNotesTemplateFromProcedureDetail procedureDetail={option.data} state={store.getState()} />,
        );
    };

    const filteredProcTemplateList = useMemo(() => {
        const options = procedureTemplateOptions.filter(
            (option) => option.displayName.toLowerCase().indexOf(procTemplateSearch.toLowerCase() ?? '') > -1,
        );

        if (!options.length) options.push({ key: '', displayName: 'No procedures found in search.', data: undefined });
        return options;
    }, [procedureTemplateOptions]);

    const procTemplateRibbonButtonOptions = useMemo(
        () => filteredProcTemplateList.reduce((a, option) => ({ ...a, [option.key]: option.displayName }), {}),
        [filteredProcTemplateList, procTemplateSearch],
    );

    const procedureTemplatePicker = usePickerPlugin<IProcedureDetail | undefined>({
        triggerCharacter: PROCEDURE_TEMPLATE_PICKER_TRIGGER_CHAR,
        suggestionOptions: procedureTemplateOptions,
        onGetSuggestionToInsert: onGetProcedureTemplateToInsert,
        onRenderListItem: (item) => {
            return (
                <>
                    <Text>
                        <strong>{item.data?.code}</strong>
                    </Text>{' '}
                    <Text style={{ textOverflow: 'ellipsis' }}>{item.data?.displayName}</Text>
                </>
            );
        },
    });

    const onSearchBoxKeyDown = useCallback((e) => {
        e.stopPropagation();
    }, []);

    const renderProcedureTemplateMenuList: IRenderFunction<IContextualMenuListProps> = useCallback(
        (menuListProps, defaultRender) => {
            return (
                <div style={{ overflowY: 'hidden' }}>
                    <div>
                        <SearchBox
                            value={procTemplateSearch ?? ''}
                            placeholder="Search procedure templates"
                            onKeyDown={onSearchBoxKeyDown}
                            onChange={(ev, value) => setProcTemplateSearch(value ?? '')}
                        />
                    </div>
                    <div style={{ overflowY: 'auto' }}>{defaultRender ? defaultRender(menuListProps) : null}</div>
                </div>
            );
        },
        [procTemplateSearch],
    );
    // Fetch data required to populate quick notes
    // Fetch chartNote associated with encounter
    useEffect(() => {
        if (patientId && encounterId) {
            dispatch(getQuickNoteProcedures({ tenantId, patientId, encounterId }));
            dispatch(getQuickNoteForms({ tenantId, patientId, encounterId }));
            dispatch(getQuickNoteNextAppointment({ tenantId, patientId }));
            dispatch(fetchPAMV({ tenantId, patientId }));
            if (loadingCurrentNote !== LoadingStatus.Pending) getChartNoteByEncounterId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encounterId, patientId, tenantId, getChartNoteByEncounterId]);

    // Cleanup chartNote when navigating away from ClinicalNote page
    useEffect(() => {
        return () => {
            dispatch(setCurrentNote(undefined));
            dispatch(cleanupQuickNoteAssets());
        };
    }, []);

    const isAttested = patientEncounter?.status === EncounterStatus.Attested;
    const isCorrectionAmend = patientEncounter?.status === EncounterStatus.CorrectionAmend;
    const isCurrentNoteFromPatientEncounter = currentNote?.encounterId === patientEncounter?.id;
    const isCorrectionNeeded = patientEncounter?.status === EncounterStatus.CorrectionsNeeded;
    const isTextFieldDisabled =
        !patientEncounter || isAttested || isCorrectionAmend || isCorrectionNeeded || !isCurrentNoteFromPatientEncounter;

    function onChange(value: string | undefined) {
        if (!isEqual(currentNote?.data.value, value) && patientId) {
            dispatch(setCurrentChartNoteDataAndSave({ propPath: 'value', value, tenantId, patientId }));
        }
    }

    const signatures = useSelector(selectCurrentClinicalNoteSignatures).map((signature, index) => (
        <NoteSignature signature={signature} key={index} />
    ));

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }} id="clinical-note">
            <Prompt when={promptUserForSave} message={SAVE_NOTE_USER_PROMPT}></Prompt>
            {!patientEncounter && (
                <MessageBar messageBarType={MessageBarType.blocked}>
                    Notes are not editable without a patient encounter. Please select or create a new patient encounter.
                </MessageBar>
            )}
            {isAttested && <MessageBar messageBarType={MessageBarType.success}>Clinical note has been attested</MessageBar>}
            {!isCurrentNoteFromPatientEncounter && patientEncounter && (
                <MessageBar messageBarType={MessageBarType.blocked}>
                    This note cannot be edited because it is not associated with the current encounter.
                </MessageBar>
            )}
            {loadingCurrentNote === 'pending' ? (
                <ProgressIndicator label="Loading Note..." />
            ) : (
                <Stack tokens={{ childrenGap: 10 }} style={{ display: 'flex', flex: 1 }} horizontal grow>
                    <Stack style={{ flex: 1, display: 'flex' }}>
                        <Stack id="chartnote_save-button" style={{ flex: 1, position: 'relative' }}>
                            {!isTextFieldDisabled && !isAttested && (
                                <Stack style={{ position: 'absolute', right: -10, top: 0, zIndex: 5 }}>
                                    <TooltipHost
                                        content={
                                            currentNote?.modifiedOn
                                                ? `Last saved: ${formatDistanceToNow(new Date(currentNote?.modifiedOn))} ago`
                                                : undefined
                                        }
                                        directionalHint={DirectionalHint.bottomCenter}
                                    >
                                        <CompoundButton
                                            iconProps={{ iconName: 'Save' }}
                                            text="Save Note"
                                            onRenderText={(props) => {
                                                return (
                                                    <Stack
                                                        horizontal
                                                        verticalAlign="center"
                                                        tokens={{ childrenGap: 10 }}
                                                        styles={{ root: { paddingRight: 10 } }}
                                                    >
                                                        <Label>{props?.text}</Label>
                                                        <SaveStatusIcon
                                                            style={{ marginTop: 5 }}
                                                            itemName="Note"
                                                            saveStatus={chartNoteSaveStatus}
                                                        />
                                                    </Stack>
                                                );
                                            }}
                                            styles={{ root: { minHeight: 44, padding: 5, border: 'none' } }}
                                            disabled={
                                                !currentNote || isAttested || isCorrectionAmend || isCorrectionNeeded || isSaving
                                            }
                                            onClick={() => (currentNote ? upsertChartNote(currentNote) : undefined)}
                                        />
                                    </TooltipHost>
                                </Stack>
                            )}
                            <ScrollablePane style={{ paddingBottom: 10 }}>
                                <TextEditor
                                    placeholder={`Add notes... (Type the character '${QUICK_NOTE_PICKER_TRIGGER_CHAR}' to search/add quick notes, or '${PROCEDURE_TEMPLATE_PICKER_TRIGGER_CHAR}' to search/add procedure templates)`}
                                    plugins={[quickNotePicker, procedureTemplatePicker]}
                                    value={currentNote?.data.value}
                                    onChange={onChange}
                                    ribbonButtons={[
                                        {
                                            key: 'quickNote',
                                            dropDownMenu: {
                                                items: quickNotePickerOptions.reduce(
                                                    (a, option) => ({ ...a, [option.key]: option.displayName }),
                                                    {},
                                                ),
                                            },
                                            unlocalizedText: 'Quick Notes',
                                            iconName: 'AddNotes',
                                            onClick: (editor, key) => {
                                                const content = onGetQuickNoteToInsert(key as NoteParser);
                                                if (content) {
                                                    //Insert content doesn't trigger content updated events, and there is no way to force this. Use setContent instead.
                                                    //Avoid element formatting weirdness by checking to see if content is empty first.
                                                    if (editor.isEmpty(true)) {
                                                        editor.setContent(content);
                                                    } else {
                                                        editor.setContent(editor.getContent() + content);
                                                    }
                                                }
                                            },
                                            isDisabled: () => isTextFieldDisabled,
                                        },
                                        {
                                            key: 'procedureTemplates',
                                            iconName: 'ScopeTemplate',
                                            dropDownMenu: {
                                                items: procTemplateRibbonButtonOptions,
                                                commandBarSubMenuProperties: {
                                                    shouldFocusOnMount: false,
                                                    delayUpdateFocusOnHover: true,
                                                    focusZoneProps: {
                                                        shouldInputLoseFocusOnArrowKey: () => true,
                                                    },
                                                    onMenuDismissed: () => {
                                                        setProcTemplateSearch('');
                                                    },
                                                    onRenderMenuList: renderProcedureTemplateMenuList,
                                                },
                                            },
                                            onClick: (editor, key) => {
                                                if (!key) return;
                                                const option = procedureTemplateOptions.find((p) => p.key === key);

                                                if (option) {
                                                    const content = onGetProcedureTemplateToInsert(option);
                                                    //Avoid element formatting weirdness by checking to see if content is empty first.
                                                    if (editor.isEmpty(true)) {
                                                        editor.setContent(content);
                                                    } else {
                                                        editor.setContent(editor.getContent() + content);
                                                    }
                                                }
                                            },
                                            unlocalizedText: 'Procedure Templates',
                                        },
                                    ]}
                                    disabled={isTextFieldDisabled}
                                />
                            </ScrollablePane>
                        </Stack>
                        <Stack
                            style={{
                                backgroundColor: theme.palette.neutralLight,
                                paddingTop: signatures.length ? 5 : 0,
                                maxHeight: 200,
                                overflowY: 'scroll',
                            }}
                            verticalAlign="start"
                            tokens={{ childrenGap: 5 }}
                        >
                            {signatures}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </div>
    );
}
export default ClinicalNote;

type NoteSignatureProps = {
    signature?: AttestSignature;
};
function NoteSignature({ signature }: NoteSignatureProps) {
    const theme = useTheme();
    const { root } = getClasses(theme);
    return (
        <Stack className={root} verticalAlign="start">
            <span dangerouslySetInnerHTML={{ __html: signature?.signature ?? '' }}></span>
        </Stack>
    );
}

const classNames = {
    root: 'note-sig',
    attestText: 'note-sig_attest-text',
    text: 'card_text',
};

const getClasses = memoizeFunction((theme: ITheme) =>
    mergeStyleSets({
        root: [
            classNames.root,
            {
                background: theme.semanticColors.bodyBackground,
                cursor: 'pointer',
                borderRadius: 3,
                transition: 'background .15s',
                padding: 5,
            },
        ],
    }),
);
