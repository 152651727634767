import { CheckInSections } from 'state/slices/scheduling/scheduling.slice';
import CheckInSection from './CheckInSection';
import PracticesAndSignature from 'components/PatientDemographics/Financial/components/PracticesAndSignature';
import Insurance from 'components/PatientDemographics/Financial/components/Insurance';
import { Stack } from '@fluentui/react';
import { selectRootFinancialInfoTasksAsList } from 'state/task-management/taskManagement.slice';
import { useSelector } from 'hooks';
import UserTasksBadge from 'components/UserTasks/UserTasksBadge';
import { uniqBy } from 'lodash';

interface IPayersSectionProps {
    isCheckin?: boolean;
    isCheckout?: boolean;
}

export default function PayersSection({ isCheckin, isCheckout }: IPayersSectionProps): JSX.Element | null {
    const payersTasks = useSelector(selectRootFinancialInfoTasksAsList);
    const isCheckinOrCheckout = isCheckin || isCheckout;

    return (
        <CheckInSection
            leftContent={
                <>
                    {payersTasks.length ? (
                        <UserTasksBadge size="small" userTasks={uniqBy(payersTasks, (item) => item.type)} />
                    ) : null}
                </>
            }
            animation={false}
            section={CheckInSections.Financial}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <PracticesAndSignature isCheckin={isCheckin} isCheckout={isCheckout} />
                <Insurance isCheckinOrCheckout={isCheckinOrCheckout} />
                {/* <Grants /> */}
            </Stack>
        </CheckInSection>
    );
}
