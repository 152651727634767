import { Checkbox, ComboBox, Stack } from '@fluentui/react';
import { Field, SubSection } from 'components';
import { useSelector } from 'hooks';
import { IValidationError, getValidationError, validateErrorMessage } from 'hooks/useValidation';
import TaskFieldLabel from 'pages/Scheduling/components/Checkin/TaskFieldLabel';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addMissingPatientSignature } from 'state/slices/edit-patient/edit-patient.actions';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientSignaturePropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { TaskType } from 'state/task-management/taskManagement.actions';
import { selectPayersInfoTasks, selectRootFinancialInfoByTaskTypeLookup } from 'state/task-management/taskManagement.slice';
import { classicDateOnly } from 'utils/dateOnly';

interface PracticesAndSignatureProps {
    isCheckin?: boolean;
    isCheckout?: boolean;
}

export enum SignatureTypeFields {
    PrivacyNotice = 'Privacy Notice',
    BillingEffectiveDate = 'Billing Effective',
    BillingExpirationDate = 'Billing Expiration',
    AssignmentEffectiveDate = 'Assignment Effective',
    AssignmentExpirationDate = 'Assignment Expiration',
}

function PracticesAndSignature({ isCheckin, isCheckout }: PracticesAndSignatureProps): JSX.Element | null {
    const patient = useSelector(selectEditPatient);
    const dispatch = useDispatch();
    const lookupRootPayerTasks = useSelector(selectRootFinancialInfoByTaskTypeLookup);
    const validationErrors = useSelector(selectEditPatientValidationErrors);
    const _selectRootFinancialInfoAsList = useSelector(selectPayersInfoTasks);

    const privacyNoticeSignedChecked = !!patient?.signature?.privacyNoticeSigned;
    const releaseOfBillingInfoChecked = !!patient?.signature?.billingReleaseSigned;
    const benefitAssignmentSignedChecked = !!patient?.signature?.benefitAssignmentSigned;

    useEffect(() => {
        if (patient && !patient.signature) {
            dispatch(addMissingPatientSignature());
        }
    }, [patient, patient?.signature, dispatch]);

    const isCheckinCheckout = isCheckin || isCheckout;

    return (
        <SubSection
            title={
                isCheckinCheckout
                    ? _selectRootFinancialInfoAsList.length
                        ? 'Notice of Privacy Practices and Signature on File'
                        : ''
                    : 'Notice of Privacy Practices and Signature on File'
            }
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end">
                    {!lookupRootPayerTasks[TaskType.privacyNoticeVerification] && isCheckinCheckout ? null : (
                        <Stack.Item>
                            <Checkbox
                                label="Privacy Notice"
                                checked={privacyNoticeSignedChecked}
                                onChange={() => {
                                    dispatch(
                                        editPatientSignaturePropChange({
                                            key: 'privacyNoticeSigned',
                                            value: !privacyNoticeSignedChecked,
                                        }),
                                    );
                                }}
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={data?.label}
                                        lookup={lookupRootPayerTasks}
                                        taskType={TaskType.privacyNoticeVerification}
                                    />
                                )}
                            />
                        </Stack.Item>
                    )}
                    {!lookupRootPayerTasks[TaskType.privacyNoticeDateVerification] &&
                    !lookupRootPayerTasks[TaskType.privacyNoticeVerification] &&
                    isCheckinCheckout ? null : (
                        <Stack.Item styles={{ root: { maxWidth: 200 } }}>
                            <Field.Date
                                label="Given"
                                value={
                                    patient?.signature?.privacyNoticeSignedDate
                                        ? classicDateOnly(patient.signature.privacyNoticeSignedDate)
                                        : ''
                                }
                                onChange={(e, value) => {
                                    dispatch(
                                        editPatientSignaturePropChange({
                                            key: 'privacyNoticeSignedDate',
                                            value: value ? value : '',
                                        }),
                                    );
                                }}
                                hasDatePicker
                                autoComplete="off"
                                disabled={!privacyNoticeSignedChecked}
                                showGoToToday
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={data?.label}
                                        lookup={lookupRootPayerTasks}
                                        taskType={TaskType.privacyNoticeDateVerification}
                                    />
                                )}
                                errorMessage={validateErrorMessage(
                                    getValidationError(validationErrors, SignatureTypeFields.PrivacyNotice),
                                )}
                            />
                        </Stack.Item>
                    )}
                </Stack>

                {!lookupRootPayerTasks[TaskType.billingReleaseVerification] &&
                !lookupRootPayerTasks[TaskType.billingReleaseExpirationVerification] &&
                isCheckinCheckout ? null : (
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end">
                        <Stack.Item>
                            <Checkbox
                                label="Release of Billing Information"
                                checked={releaseOfBillingInfoChecked}
                                onChange={() => {
                                    dispatch(
                                        editPatientSignaturePropChange({
                                            key: 'billingReleaseSigned',
                                            value: !releaseOfBillingInfoChecked,
                                        }),
                                    );
                                }}
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={data?.label}
                                        lookup={lookupRootPayerTasks}
                                        taskType={TaskType.billingReleaseVerification}
                                    />
                                )}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <Field.Date
                                label="Effective"
                                value={
                                    patient?.signature?.billingReleaseSignedDate
                                        ? classicDateOnly(patient.signature.billingReleaseSignedDate)
                                        : ''
                                }
                                onChange={(e, value) => {
                                    dispatch(
                                        editPatientSignaturePropChange({
                                            key: 'billingReleaseSignedDate',
                                            value: value ? value : '',
                                        }),
                                    );
                                }}
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={data?.label}
                                        lookup={lookupRootPayerTasks}
                                        taskType={TaskType.billingReleaseEffectiveDateVerification}
                                    />
                                )}
                                hasDatePicker
                                autoComplete="off"
                                disabled={!releaseOfBillingInfoChecked}
                                showGoToToday
                                errorMessage={validateErrorMessage(
                                    getValidationError(validationErrors, SignatureTypeFields.BillingEffectiveDate),
                                )}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <Field.Date
                                label="Expiration"
                                value={
                                    patient?.signature?.billingReleaseExpirationDate
                                        ? classicDateOnly(patient.signature.billingReleaseExpirationDate)
                                        : ''
                                }
                                onChange={(e, value) => {
                                    dispatch(
                                        editPatientSignaturePropChange({
                                            key: 'billingReleaseExpirationDate',
                                            value: value ? value : '',
                                        }),
                                    );
                                }}
                                hasDatePicker
                                autoComplete="off"
                                disabled={!releaseOfBillingInfoChecked}
                                showGoToToday
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={data?.label}
                                        lookup={lookupRootPayerTasks}
                                        taskType={TaskType.billingReleaseExpirationVerification}
                                    />
                                )}
                                errorMessage={validateErrorMessage(
                                    getValidationError(validationErrors, SignatureTypeFields.BillingExpirationDate),
                                )}
                            />
                        </Stack.Item>
                    </Stack>
                )}

                {!lookupRootPayerTasks[TaskType.benefitAssignmentVerification] &&
                !lookupRootPayerTasks[TaskType.benefitAssignmentExpirationVerification] &&
                isCheckinCheckout ? null : (
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end">
                        <Stack.Item>
                            <Checkbox
                                label="Assignment of Benefits"
                                checked={benefitAssignmentSignedChecked}
                                onChange={() => {
                                    dispatch(
                                        editPatientSignaturePropChange({
                                            key: 'benefitAssignmentSigned',
                                            value: !benefitAssignmentSignedChecked,
                                        }),
                                    );
                                }}
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={data?.label}
                                        lookup={lookupRootPayerTasks}
                                        taskType={TaskType.benefitAssignmentVerification}
                                    />
                                )}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <Field.Date
                                label=" Effective "
                                value={
                                    patient?.signature?.benefitAssignmentSignedDate
                                        ? classicDateOnly(patient.signature.benefitAssignmentSignedDate)
                                        : ''
                                }
                                onChange={(e, value) => {
                                    dispatch(
                                        editPatientSignaturePropChange({
                                            key: 'benefitAssignmentSignedDate',
                                            value: value ? value : '',
                                        }),
                                    );
                                }}
                                hasDatePicker
                                autoComplete="off"
                                disabled={!benefitAssignmentSignedChecked}
                                showGoToToday
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={data?.label}
                                        lookup={lookupRootPayerTasks}
                                        taskType={TaskType.benefitAssignmentEffectiveDateVerification}
                                    />
                                )}
                                errorMessage={validateErrorMessage(
                                    getValidationError(validationErrors, SignatureTypeFields.AssignmentEffectiveDate),
                                )}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <Field.Date
                                label="Expiration"
                                value={
                                    patient?.signature?.benefitAssignmentExpirationDate
                                        ? classicDateOnly(patient.signature.benefitAssignmentExpirationDate)
                                        : ''
                                }
                                onChange={(e, value) => {
                                    dispatch(
                                        editPatientSignaturePropChange({
                                            key: 'benefitAssignmentExpirationDate',
                                            value: value ? value : '',
                                        }),
                                    );
                                }}
                                hasDatePicker
                                autoComplete="off"
                                disabled={!benefitAssignmentSignedChecked}
                                showGoToToday
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={data?.label}
                                        lookup={lookupRootPayerTasks}
                                        taskType={TaskType.benefitAssignmentExpirationVerification}
                                    />
                                )}
                                errorMessage={validateErrorMessage(
                                    getValidationError(validationErrors, SignatureTypeFields.AssignmentExpirationDate),
                                )}
                            />
                        </Stack.Item>
                    </Stack>
                )}

                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    {!lookupRootPayerTasks[TaskType.signatureSourceVerification] && isCheckinCheckout ? null : (
                        <Stack.Item>
                            <ComboBox
                                options={[
                                    {
                                        key: '',
                                        text: '(Select Source)',
                                    },
                                    {
                                        key: 'Paper signature on file',
                                        text: 'Paper signature on file',
                                    },
                                    {
                                        key: 'Electronic signature on file',
                                        text: 'Electronic signature on file',
                                    },
                                    {
                                        key: 'Signature generated by provider because patient not present',
                                        text: 'Signature generated by provider because patient not present',
                                    },
                                    {
                                        key: 'Other',
                                        text: 'Other',
                                    },
                                ]}
                                label="Signature Source"
                                placeholder="(Select Source)"
                                selectedKey={
                                    patient && patient.signature && patient.signature.signatureSourceId
                                        ? patient.signature.signatureSourceId
                                        : undefined
                                }
                                onChange={(e, option) => {
                                    if (option)
                                        dispatch(
                                            editPatientSignaturePropChange({
                                                key: 'signatureSourceId',
                                                value: option.key as string,
                                            }),
                                        );
                                }}
                                disabled={
                                    !privacyNoticeSignedChecked && !releaseOfBillingInfoChecked && !benefitAssignmentSignedChecked
                                }
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={'Signature Source'}
                                        lookup={lookupRootPayerTasks}
                                        taskType={TaskType.signatureSourceVerification}
                                    />
                                )}
                            />
                        </Stack.Item>
                    )}
                </Stack>
            </Stack>
        </SubSection>
    );
}
export default PracticesAndSignature;
