import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { EncounterStatusFilterOptions, LedgerState, LedgerSummaryFilters, PostPaymentModalContext } from './ledger.state';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { getEncounterLedgerSummary, getLedgerTotalsView, initializeTotalsModal, rebillEncounterById } from './ledger.actions';
import { ICommonTransaction, IEncounterSummary } from 'api/models/encounter-ledger.model';
import { IPaymentSource } from 'api/models/payment-source.model';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { IBatch } from 'api/models/batch.model';
import { RelativeDateType } from 'components/Field/RelativeDateRangeField';
import getBillingProcedureAmountFromProp from 'utils/getBillingProcedureAmountFromProp';

export const reducers = {
    cleanupSummaryLedgerState: (state: LedgerState): void => {
        state.loadingLedgerSummary = LoadingStatus.Idle;
        state.ledgerSummary = undefined;
    },
    cleanupCurrentTransactionsAndSource: (state: LedgerState): void => {
        state.currentPaymentSource = undefined;
        state.currentTransactions = [];
        state.currentBillingProcedures = [];

        state.postPaymentModalOpen = false;
        state.postPaymentModalContext = undefined;

        state.adjustmentModalOpen = false;
        state.totalAdjustmentAmount = 0;

        state.prePaymentModalOpen = false;
    },
    cleanupTotalsModal: (state: LedgerState): void => {
        state.commonTransactions = [];
        state.paymentSources = [];
        state.totalsModalIsOpen = false;
    },
    updateTransactionAmount: (state: LedgerState, action: PayloadAction<{ id: string; amount: number }>): void => {
        if (state.currentTransactions.length) {
            const indexOfTransaction = state.currentTransactions.findIndex((transaction) => transaction.id === action.payload.id);
            if (indexOfTransaction > -1) {
                state.currentTransactions[indexOfTransaction].amount = action.payload.amount;
            }
        }
    },
    updateAdjustmentReasons: (state: LedgerState, action: PayloadAction<string>): void => {
        if (state.currentTransactions.length) {
            state.currentTransactions = state.currentTransactions.map((t) => ({ ...t, adjustmentReasonId: action.payload }));
        }
    },
    toggleLedgerInsuranceBalanceGreaterThanZeroFilter: (state: LedgerState): void => {
        state.ledgerSummaryFilters.insuranceBalanceGreaterThanZero = !state.ledgerSummaryFilters.insuranceBalanceGreaterThanZero;
    },
    toggleLedgerFilters: (state: LedgerState): void => {
        state.showFilters = !state.showFilters;
    },
    setLedgerEncounterDateRangeFilters: (
        state: LedgerState,
        action: PayloadAction<{ startDate?: string; endDate?: string; relativeDateType: RelativeDateType }>,
    ): void => {
        const { startDate, endDate, relativeDateType } = action.payload;

        state.ledgerSummaryFilters.relativeDateType = relativeDateType;
        state.ledgerSummaryFilters.startDate = startDate;
        state.ledgerSummaryFilters.endDate = endDate;
    },
    setLedgerDateFilters: (
        state: LedgerState,
        action: PayloadAction<{
            path: keyof Pick<LedgerSummaryFilters, 'startDate' | 'endDate'>;
            value: string | undefined;
        }>,
    ): void => {
        const { path, value } = action.payload;
        state.ledgerSummaryFilters[path] = value;
        if (state.ledgerSummaryFilters.relativeDateType) state.ledgerSummaryFilters.relativeDateType = undefined;
    },
    setLedgerEncounterBillingProvidersFilter: (state: LedgerState, action: PayloadAction<string>): void => {
        if (!state.ledgerSummaryFilters.billingProviders) {
            state.ledgerSummaryFilters.billingProviders = [action.payload];
        } else {
            const indexOfProvider = state.ledgerSummaryFilters.billingProviders.indexOf(action.payload);
            if (indexOfProvider > -1) {
                state.ledgerSummaryFilters.billingProviders = [
                    ...state.ledgerSummaryFilters.billingProviders.slice(0, indexOfProvider),
                    ...state.ledgerSummaryFilters.billingProviders.slice(indexOfProvider + 1),
                ];
            } else {
                state.ledgerSummaryFilters.billingProviders = [...state.ledgerSummaryFilters.billingProviders, action.payload];
            }
        }
    },
    setEncounterLedgerSummaryFilter: (
        state: LedgerState,
        action: PayloadAction<EncounterStatusFilterOptions>,
    ): void => {
        state.ledgerSummaryFilters.encounterBillingStatus = action.payload;
    },
    clearAllLedgerFilters: (state: LedgerState): void => {
        state.ledgerSummaryFilters = { encounterBillingStatus: EncounterStatusFilterOptions.All };
    },
    setFilteredEncounterSummaries: (state: LedgerState, action: PayloadAction<IEncounterSummary[] | undefined>): void => {
        state.filteredLedgerSummaries = action.payload;
    },
    createTransactionAmount: (state: LedgerState, action: PayloadAction<ICommonTransaction>): void => {
        state.currentTransactions = [...state.currentTransactions, action.payload];
    },
    setPostPaymentModalContext: (state: LedgerState, action: PayloadAction<PostPaymentModalContext | undefined>): void => {
        state.postPaymentModalContext = action.payload;
    },
    setCurrentPaymentSource: (state: LedgerState, action: PayloadAction<IPaymentSource | undefined>): void => {
        state.currentPaymentSource = action.payload;
    },
    setCurrentPaymentTransactions: (state: LedgerState, action: PayloadAction<ICommonTransaction[]>): void => {
        state.currentTransactions = action.payload;
    },
    updateTransactionsBatchIdAndDateOfEntry: (state: LedgerState, action: PayloadAction<IBatch>): void => {
        const { id: batchId, dateOfEntry } = action.payload;
        state.currentTransactions = state.currentTransactions.map((transaction) => ({ ...transaction, batchId, dateOfEntry }));
    },
    setCurrentBillingProcedures: (state: LedgerState, action: PayloadAction<IBillingProcedure[]>): void => {
        state.currentBillingProcedures = action.payload;
    },
    updateCurrentPaymentSourceProps: (
        state: LedgerState,
        action: PayloadAction<{ path: keyof IPaymentSource; value: unknown }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.currentPaymentSource) state.currentPaymentSource = { ...state.currentPaymentSource, [path]: value };
    },
    // Ability to edit currentPaymentSource properties
    // Ability to edit specific Transaction amounts

    // Payment Modal Distribution Buttons
    // distributeOldestFirst: (state: LedgerState): void => {},
    distributePaymentEvenly: (state: LedgerState): void => {
        const paymentAmount = state.currentPaymentSource?.amount;
        if (paymentAmount) {
            const distributedAmount = paymentAmount / state.currentBillingProcedures.length;
            state.currentTransactions = [...state.currentTransactions.map((t) => ({ ...t, amount: distributedAmount }))];
        }
    },
    payAllTransactions: (state: LedgerState, { payload }: PayloadAction<{ feeProp: keyof IBillingProcedure }>): void => {
        const { feeProp } = payload;
        if (state.currentTransactions) {
            let remainingAmount = state.currentPaymentSource?.amount ?? 0;

            const newTransactions = state.currentBillingProcedures
                .filter((bp) => getBillingProcedureAmountFromProp(bp, feeProp) > 0)
                .map((bp) => {
                    const transaction = state.currentTransactions.find((t) => t.chartProcedureId === bp.id);
                    if (transaction) {
                        const fee = getBillingProcedureAmountFromProp(bp, feeProp);
                        const amount = remainingAmount < fee ? remainingAmount : fee;

                        remainingAmount = Number((remainingAmount - amount).toFixed(2));

                        return { ...transaction, amount: amount > 0 ? amount : transaction?.amount };
                    } else {
                        return undefined;
                    }
                })
                .filter((t) => t !== undefined) as ICommonTransaction[];

            state.currentTransactions = [
                ...newTransactions,
                ...state.currentTransactions.filter((t) => newTransactions.findIndex((nt) => nt.id === t.id) === -1),
            ];
        }
    },
    adjustAllTransactions: (state: LedgerState): void => {
        if (state.currentTransactions) {
            let remainingAmount = state.totalAdjustmentAmount ?? 0;

            const newTransactions = state.currentBillingProcedures
                .filter((bp) => bp.patientBalance > 0)
                .map((bp) => {
                    const transaction = state.currentTransactions.find((t) => t.chartProcedureId === bp.id);
                    if (transaction) {
                        const patientBalance = bp.patientBalance ?? 0;
                        const amount = remainingAmount < patientBalance ? remainingAmount : patientBalance;

                        remainingAmount = Number((remainingAmount - amount).toFixed(2));

                        return { ...transaction, amount: amount > 0 ? amount : transaction?.amount };
                    } else {
                        return undefined;
                    }
                })
                .filter((t) => t !== undefined) as ICommonTransaction[];

            state.currentTransactions = [
                ...newTransactions,
                ...state.currentTransactions.filter((t) => newTransactions.findIndex((nt) => nt.id === t.id) === -1),
            ];
        }
    },
    clearTransactions: (state: LedgerState): void => {
        if (state.currentTransactions.length) {
            state.currentTransactions = [...state.currentTransactions.map((t) => ({ ...t, amount: 0 }))];
        }
    },

    // Modals
    setPostPaymentModalOpen: (state: LedgerState, action: PayloadAction<boolean>): void => {
        state.postPaymentModalOpen = action.payload;
    },
    setAdjustmentModalOpen: (state: LedgerState, action: PayloadAction<boolean>): void => {
        state.adjustmentModalOpen = action.payload;
    },
    setTotalAdjustmentAmount: (state: LedgerState, action: PayloadAction<number | undefined>): void => {
        state.totalAdjustmentAmount = action.payload;
    },
    setPrepaymentModalOpen: (state: LedgerState, action: PayloadAction<boolean>): void => {
        state.prePaymentModalOpen = action.payload;
    },
};

export const extraReducers = (builder: ActionReducerMapBuilder<LedgerState>): ActionReducerMapBuilder<LedgerState> =>
    builder

        // Get encounter ledger procedure summary
        .addCase(getEncounterLedgerSummary.pending, (state) => {
            state.loadingProcedureSummaries = LoadingStatus.Pending;
        })
        .addCase(getEncounterLedgerSummary.fulfilled, (state, { payload }) => {
            const { encounterId } = payload;
            state.loadingProcedureSummaries = LoadingStatus.Completed;
            if (encounterId) {
                state.procedureSummaries[encounterId] = payload;
            }
        })
        .addCase(getEncounterLedgerSummary.rejected, (state, { error }) => {
            if (error.name !== 'AbortError') {
                state.loadingProcedureSummaries = LoadingStatus.Failed;
            } else {
                state.loadingProcedureSummaries = LoadingStatus.Idle;
            }
        })

        // Get ledger totals view
        .addCase(getLedgerTotalsView.pending, (state) => {
            state.loadingLedgerSummary = LoadingStatus.Pending;
        })
        .addCase(getLedgerTotalsView.fulfilled, (state, action) => {
            state.loadingLedgerSummary = LoadingStatus.Completed;
            state.ledgerSummary = action.payload.encounterSummaries;
        })
        .addCase(getLedgerTotalsView.rejected, (state, { error }) => {
            if (error.name !== 'AbortError') {
                state.loadingLedgerSummary = LoadingStatus.Failed;
            } else {
                state.loadingLedgerSummary = LoadingStatus.Idle;
            }
        })

        .addCase(initializeTotalsModal.pending, (state) => {
            state.loadingCommonTransactions = LoadingStatus.Pending;
            state.totalsModalIsOpen = true;
        })
        .addCase(initializeTotalsModal.fulfilled, (state, action) => {
            state.loadingCommonTransactions = LoadingStatus.Completed;
            state.commonTransactions = action.payload.commonTransactions;
            state.paymentSources = action.payload.paymentSources;
        })
        .addCase(initializeTotalsModal.rejected, (state) => {
            state.loadingCommonTransactions = LoadingStatus.Failed;
        })

        // Handle encounter rebill action
        .addCase(rebillEncounterById.pending, (state, { meta }) => {
            if (!state.encounterRebillLoading.length) state.encounterRebillLoading = [meta.arg.encounterId];
            state.encounterRebillLoading = state.encounterRebillLoading = [...state.encounterRebillLoading, meta.arg.encounterId];
        })
        .addCase(rebillEncounterById.fulfilled, (state, { payload: encounterSummary }) => {
            state.ledgerSummary = state.ledgerSummary?.map((summary) => {
                if (summary.encounterId === encounterSummary.encounterId) {
                    return encounterSummary;
                }
                return summary;
            });
            if (state.encounterRebillLoading.length)
                state.encounterRebillLoading = state.encounterRebillLoading.filter((id) => id !== encounterSummary.encounterId);
        })
        .addCase(rebillEncounterById.rejected, (state, { meta }) => {
            if (state.encounterRebillLoading.length)
                state.encounterRebillLoading = state.encounterRebillLoading.filter((id) => id !== meta.arg.encounterId);
        });
